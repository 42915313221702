import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useRef } from 'react';
import { login } from '../../features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Link from '@material-ui/core/Link';
import ResetPwDialog from './ResetPwDialog';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import WaitIndicator from '../../components/ui/WaitIndicator';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = () => {
  const classes = useStyles();
  const emailTextFieldRef = useRef();
  const passwordTextFieldRef = useRef();
  const dispatch = useDispatch();
  const { isLoggedIn, isLoginPending } = useSelector(state => state.auth); 
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [ pwResetDialogOpened, setPwResetDialogOpened ] = React.useState(false);

  const onLoginButtonClicked = async (event) => {
      event.preventDefault();
      const emailAddress = emailTextFieldRef.current.value;
      const password = passwordTextFieldRef.current.value;

      if (emailAddress.length == 0 || password.length == 0) {
        enqueueSnackbar('Please enter your email and password', { variant: 'warning'});
        return;
      }

      try {
        await dispatch(login({username: emailAddress, password: password})).unwrap();
      } catch(error) {
        console.log('Error:' + JSON.stringify(error));
        if (error.message.includes('password'))
          enqueueSnackbar('Incorrect username and/or password', { variant: 'warning'});
        else
          enqueueSnackbar('Login error', { variant: 'warning'});
      }
  }


  const onForgotPwClicked = (event) => {
    event.preventDefault();
    setPwResetDialogOpened(true)
  }
  
  const handlePwResetClosed = () => {
    setPwResetDialogOpened(false)
  }

  const handlePwResetWithEmail = async (email) => {
    const auth = getAuth();
    await sendPasswordResetEmail(auth, email);
    setPwResetDialogOpened(false);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <ResetPwDialog 
          isOpened={pwResetDialogOpened} 
          handlePwResetWithEmail={handlePwResetWithEmail} 
          handleClose={handlePwResetClosed} 
        />
        {isLoginPending ?
        <WaitIndicator /> 
        :
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>      
        }
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                inputRef={emailTextFieldRef}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                inputRef={passwordTextFieldRef}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onLoginButtonClicked}
          >
            Login
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component="button" variant="body2"  onClick={onForgotPwClicked}>
                Forgot password?
              </Link>
            </Grid>          
          </Grid>         
        </form>
      </div>
    </Container>
  );
}

export default Login;