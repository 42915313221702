import React from 'react'
import { AppBar, Typography } from '@material-ui/core';
import { Toolbar, IconButton, Button } from '@material-ui/core';
import MenuIcon from '@mui/icons-material/Menu'
import UserOpsButton from './UserOpsButton';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../images/logo.png'; // with import

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    toolBarMargin: {
      ...theme.mixins.toolbar,
      marginTop: theme.spacing(2)
    }
  }));

const Header = ({setDrawerOpened }) => {

    const classes = useStyles(); 

    return (
        <div className={classes.root}>
            <AppBar position="fixed">
                <Toolbar>
                  <IconButton edge="start" 
                              className={classes.menuButton} 
                              color="inherit" 
                              aria-label="menu"
                              onClick={() => setDrawerOpened(true)}>
                      <MenuIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                      Loop Dash
                  </Typography>
                  <img src={logo}/>
                  <UserOpsButton />
                </Toolbar>
            </AppBar>
            <div className={classes.toolBarMargin}/>
        </div>
    );
};

export default Header;
