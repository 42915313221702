import * as React from 'react';
import { Checkbox } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { Stack, Button } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Avatar, CircularProgress, Typography, IconButton } from '@material-ui/core';
import { Grid, Box } from '@material-ui/core';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';


const useStyles = makeStyles((theme) => ({
  sizeAvatar: {
    height: theme.spacing(8),
    width: theme.spacing(8)
  },
  listItemText: {
    marginLeft: theme.spacing(1)
  }
}));


export default function VideoList({ height, videoListLoading, idsBeingProcessed, idsBeingUploaded, videosSelectable, videosDeleteable, handleRemoveVideo, videoList, selectedVideoIds, prevExists, nextExists, handleFirstClick, handlePrevClick, handleNextClick, handleVideoChecked, handleVideoUnchecked }) {

  const classes = useStyles();

  const handleSelectionChange = (video) => {
    return (event) => {
      if (event.target.checked)
        handleVideoChecked(video);
      else
        handleVideoUnchecked(video);
    }
  }

  const renderLoadingScreen = () => {
    return (
        (videoListLoading && (videoList.length === 0)) &&
        <Grid style={{z: 10 }} container justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>    
    );
  }

  const renderVideoStatus = (video) => {
    return (
      <CircularProgress
        size={60} 
        thickness={6} 
        style={{color: idsBeingProcessed?.includes(video.id) ? 'green' : '#4267B2'}}
      />
    );
  }

  const renderSecondaryAction = (video) => {
      if (videosSelectable) {
        return <Checkbox
          edge="end"
          disabled={video.videoId == ""}
          onChange={handleSelectionChange(video)}
          defaultChecked={selectedVideoIds?.includes(video.id)}                
        />
      }

      if (videosDeleteable) {
        return <IconButton
          edge="end"
          onClick={() => handleRemoveVideo(video)}               
        >
          <RemoveCircleIcon />
        </IconButton>  
      }

      return "";
  }

  return (
    <div>
      {(prevExists || nextExists) &&
      <Stack  direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
        <IconButton disabled={!prevExists} onClick={handleFirstClick} variant="text"><FirstPageIcon /></IconButton>
        <IconButton disabled={!prevExists} onClick={handlePrevClick} variant="text"><ArrowBackIosIcon /></IconButton>
        <IconButton disabled={!nextExists} onClick={handleNextClick} variant="text"><ArrowForwardIosIcon /></IconButton>
        <CircularProgress style={{visibility: videoListLoading && videoList.length > 0 ? 'visible' : 'hidden'}} />
      </Stack> 
      }      
      <List dense sx={{ maxHeight: height, width: '100%', overflow: 'auto', bgcolor: 'background.paper' }}>  
        {renderLoadingScreen()}            
        {videoList.map((video) => (
          <ListItem key={video.id}
            secondaryAction={renderSecondaryAction(video)}
            disablePadding 
          >
             <ListItemButton>
              <ListItemAvatar>
                {(!idsBeingProcessed?.includes(video.id) && !idsBeingUploaded?.includes(video.id)) && <Avatar className={classes.sizeAvatar} alt={`Avatar n°${video.id + 1}`} src={video.thumbnail || '/video-placeholder.png'} />}
                {(idsBeingProcessed?.includes(video.id) || idsBeingUploaded?.includes(video.id)) && renderVideoStatus(video)}
              </ListItemAvatar>
              <ListItemText className={classes.listItemText} primary={video.title} secondary={
                
                  <React.Fragment>
                    <Typography
                      component="div"
                      variant="body2"
                      color="text.primary"
                    >
                      {'Date: ' + (new Date(video.created)).toLocaleString()}
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      color="text.primary"
                    >
                      {'User: ' + video.userDisplayName}
                    </Typography>
                  </React.Fragment>
                
              }/>
            </ListItemButton> 
          </ListItem>))} 
        </List>            
    </div>
  );
}