import { React } from 'react';
import { Typography, Grid } from '@material-ui/core';

const Error404 = () => {
    return (
        <Grid container justifyContent="center" sx={{mt:4}}>
            <Typography variant="h5">Can not find the page you requested</Typography>
        </Grid>
    );
};

export default Error404;