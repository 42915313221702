import { React, useEffect, useState } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import axios from "axios";
import { getAuth } from "@firebase/auth";
import { useSnackbar } from "notistack";
import { DataGrid } from "@mui/x-data-grid";
import BasicDateRangePicker from "../../components/ui/BasicDateRangePicker";
import { Stack, Grid, Paper } from "@mui/material";
import format from "date-fns/format";
import { css, cx } from '@emotion/css'

const getRetentionColor = (rate) => {
  return `rgba(0,100,255,${rate})`;
};

const getRetentionRate = (rate) => {
  return (rate * 100).toFixed(2) + "%";
};

const oneTo30 = Array.from({ length: 30 }, (v, i) => {
  return { field: `Retention Rate Day ${i+1}`, 
          headerName: `Day ${i+1}`, 
          flex: 1, 
          disableClickEventBubbling: true,
          valueFormatter: ({ value }) => getRetentionRate(value),
          cellClassName : ({ value }) => {
            const style = "background-color:" + getRetentionColor(value);
             if (style) {
                  const className = css(style);
                  return className;
               } else {
                 return "";
             }
          }         
        };
});

const columns = [
  { field: "Media Source", headerName: "Media Source", flex: 1, disableClickEventBubbling: true },
  { field: "Campaign", headerName: "Campaign", flex: 2, disableClickEventBubbling: true },
  ...oneTo30
];


const getOneWeekBefore = () => {
  var date = new Date();
  date.setDate(date.getDate() - 7);
  return date;
}

const defaultFilterData = {
  from: format(getOneWeekBefore(), "yyyy-MM-dd"),
  to: format(new Date(), "yyyy-MM-dd"),
  appName: "Match Color",
};

const RetentionCard = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [retentionData, setRetentionData] = useState([]);
  const [isRetentionDataLoading, setIsRetentionDataLoading] = useState([]);
  const [retentionFilterData, setRetentionFilterData] =
    useState(defaultFilterData);

  useEffect(async () => {
    setIsRetentionDataLoading(true);

    const auth = getAuth();
    try {
      const tokenRes = await auth.currentUser.getIdToken();
      const res = await axios.post("/api/retention", retentionFilterData, {
        headers: {
          Authorization: "Bearer " + tokenRes,
        },
      });

      setRetentionData(res.data);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Can not get retention data", { variant: "error" });
    }

    setIsRetentionDataLoading(false);
  }, [retentionFilterData]);
  /*
    {
        "Retention Rate Day 2": "0.1600",
        "Campaign": "MatchColor_BRA_drd_T4",
        "Media Source": "Facebook Ads",
        "Retention Rate Day 1": "0.2000",
        "Retention Rate Day 3": "0.0400"
    },
*/   

  const handleDateRangeChange = (dateRange) => {    
    setRetentionFilterData({...retentionFilterData, from: format(dateRange[0], "yyyy-MM-dd"), to: format(dateRange[1], "yyyy-MM-dd")})
  }

  const renderLoadingScreen = () => {
    return (        
        isRetentionDataLoading &&  
        <Grid style={{z: 10 }} container justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
    );
  }

  return (
    <div>
      {!isRetentionDataLoading && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Typography sx={{ m: 1 }} variant="h5">Retention</Typography>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              <BasicDateRangePicker
                startDate={retentionFilterData.from}
                endDate={retentionFilterData.to}
                handleChange={handleDateRangeChange}
              />
            </Stack>
          </Stack>
          <div style={{ marginTop: 20 }} />
          <Paper style={{ minWidth: 5100 }} elevation={3}>
            <DataGrid
              style={{ minWidth: 5000 }}
              rows={retentionData}
              columns={columns}
              pagination={false}
              autoHeight
              getRowId={(row) => row["Campaign"]}
            />
          </Paper>
        </>
      )}
      {renderLoadingScreen()}
    </div>
  );
};

export default RetentionCard;
