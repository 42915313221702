import * as React from 'react';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core//MenuItem';
import ListItemIcon from '@material-ui/core//ListItemIcon';
import IconButton from '@material-ui/core//IconButton';
import Tooltip from '@material-ui/core//Tooltip';
import Settings from '@mui/icons-material/Settings';
import ExitToApp from '@mui/icons-material/ExitToApp';
import authSlice from '../../features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import ChangePasswordDialog from './ChangePasswordDialog';
import { getAuth, updatePassword, reauthenticateWithCredential } from "firebase/auth";
import { useSnackbar } from 'notistack';
import { EmailAuthProvider } from '@firebase/auth';

export default function UserOpsButton() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const { displayName } = useSelector(state => state.auth.user);
  const [ changePwDialogOpened, setChangePwDialogOpened] = React.useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    dispatch(authSlice.actions.logout());
  };

  const handleChangePassword = async (oldPw, pw) => {
    
    setChangePwDialogOpened(false);

    const auth = getAuth();

    const user = auth.currentUser;

    try {

      await reauthenticateWithCredential(user, EmailAuthProvider.credential(user.email, oldPw));
      await updatePassword(user, pw);

    } catch(error) {

      console.log('Error:' + JSON.stringify(error));
      enqueueSnackbar('Password could not be changed', { variant: 'warning'});

    }
  }

  const handleChangePasswordClick = () => {
    setChangePwDialogOpened(true);
  }

  return (
    <React.Fragment>
      {changePwDialogOpened && <ChangePasswordDialog handleClose={() => setChangePwDialogOpened(false)} handleChangePassword={handleChangePassword}/>}
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="User settings">
          <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
            <Avatar sx={{ width: 32, height: 32 }}>{displayName.slice(0,1)}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{          
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleChangePasswordClick}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Change Password
        </MenuItem>
        <MenuItem onClick={handleLogoutClick}>
          <ListItemIcon>
            <ExitToApp fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
