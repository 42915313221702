import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, Select, InputLabel, MenuItem, Box } from '@mui/material';
import { useSelector } from 'react-redux';


export default function AddUserDialog({ handleClose, handleAdd, handleEdit, user2Edit }) {

    const displayNameTextFieldRef = React.useRef();
    const emailTextFieldRef = React.useRef();
    const passwordTextFieldRef = React.useRef();
    const repasswordTextFieldRef = React.useRef();
    const roleBoxRef = React.useRef();
    const [ errorTexts, setErrorTexts ] = React.useState({
      displayName: '',
      email: '',
      password: '',
      repassword: ''
    });

    const isUserUpdatePending = useSelector(state => state.users.isUserUpdatePending);

    const checkDisplayName = () => {      
      const displayName = displayNameTextFieldRef.current.value;
      if (displayName.length === 0) {
        setErrorTexts({ ...errorTexts, displayName: 'Display name can not be empty'});
        return false;
      }
      else {    
        setErrorTexts({ ...errorTexts, displayName: ''});
      }

      return true;
    }

    const checkEmail = () => {
      if (user2Edit)
        return true;
      const email = emailTextFieldRef.current.value;
      if (email.length === 0) {
        setErrorTexts({ ...errorTexts, email: 'Email address can not be empty'});
        return false;
      }
      else {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(email)) {
          setErrorTexts({ ...errorTexts, email: 'Invalid email address'});
          return false;
        }
        else {
          setErrorTexts({ ...errorTexts, email: ''});
        }
      }

      return true;
    }

    const checkPassword = () => {
      if (user2Edit)
        return true;
      const password = passwordTextFieldRef.current.value;
      if (password.length === 0) {
        setErrorTexts({ ...errorTexts, password: 'Password can not be empty'});
        return false;
      }
      else {
        if (password.length < 6) {
          setErrorTexts({ ...errorTexts, password: 'Password must be at least 6 characters long'});
          return false;
        }
        else {
          setErrorTexts({ ...errorTexts, password: ''});
        }        
      }

      return true;
    }

    const checkRepassword = () => {
      if (user2Edit)
        return true;
      const password = passwordTextFieldRef.current.value;
      const repassword = repasswordTextFieldRef.current.value;
      if (password.length && (repassword != password)) {
        setErrorTexts({ ...errorTexts, repassword: 'Passwords dont match'});
        return false;
      }
      else {
        setErrorTexts({ ...errorTexts, repassword: ''});
      }

      return true;
    }

    const handleAddClick = () => {
      if (
          errorTexts.email.length +
          errorTexts.password.length +
          errorTexts.repassword.length +
          errorTexts.displayName.length)
        return;

      if (checkEmail() && checkDisplayName() && checkPassword() && checkRepassword()) {
        if (user2Edit) {
          const userDetails = {
            ...user2Edit,
            displayName: displayNameTextFieldRef.current.value,
            role: roleBoxRef.current.value,
          };

          handleEdit(userDetails);
        } else {
          const userDetails = {
            displayName: displayNameTextFieldRef.current.value,
            email: emailTextFieldRef.current.value,
            password: passwordTextFieldRef?.current.value,
            role: roleBoxRef.current.value,
          };

          handleAdd(userDetails);
        }
      }
    };

    const handleDisplayNameChange = () => {
      checkDisplayName();
    }

    const handleEmailChange = () => {
      checkEmail();
    }

    const handlePasswordChange = () => {
      checkPassword();
    }

    const handleRepasswordChange = () => {
      checkRepassword();
    }

  return (
    <div>
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle>{user2Edit ? 'Edit User' : 'Add User'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter user details
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Display Name"
            type="text"
            fullWidth
            variant="standard"
            inputRef={displayNameTextFieldRef}
            defaultValue={user2Edit?.displayName}
            error={errorTexts.displayName.length === 0 ? false : true }
            helperText={errorTexts.displayName}
            onChange={handleDisplayNameChange}
          />  
          <TextField            
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            inputRef={emailTextFieldRef}
            disabled={user2Edit}
            defaultValue={user2Edit?.email}
            error={errorTexts.email.length === 0 ? false : true }
            helperText={errorTexts.email}
            onChange={handleEmailChange}
          />
          {!user2Edit && 
          <React.Fragment>
            <TextField
              margin="dense"
              label="Password"
              type="password"
              fullWidth
              variant="standard"
              inputRef={passwordTextFieldRef} 
              error={errorTexts.password.length === 0 ? false : true }
              helperText={errorTexts.password}  
              onChange={handlePasswordChange}         
            /> 
            <TextField
              margin="dense"
              label="Re-Enter Password"
              type="password"
              fullWidth
              variant="standard"
              inputRef={repasswordTextFieldRef} 
              error={errorTexts.repassword.length === 0 ? false : true }
              helperText={errorTexts.repassword} 
              onChange={handleRepasswordChange}          
            />  
          </React.Fragment>       
          }
          <Box sx={{mt: 2}}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                id="role-select"
                label="Role"
                inputRef={roleBoxRef}
                defaultValue={user2Edit?.role}
              >
                <MenuItem value={0}>Not Assigned</MenuItem>
                <MenuItem value={1}>Owner</MenuItem>
                <MenuItem value={2}>Administrator</MenuItem>
                <MenuItem value={3}>Growth</MenuItem>
                <MenuItem value={4}>Creative</MenuItem>
                <MenuItem value={5}>Product</MenuItem>
              </Select>  
            </FormControl> 
          </Box>  
        </DialogContent>
        <DialogActions>
          <Button disabled={isUserUpdatePending} onClick={handleClose}>Cancel</Button>
          <Button disabled={isUserUpdatePending || (errorTexts.displayName.length + errorTexts.email.length + errorTexts.password.length + errorTexts.repassword.length > 0)} onClick={handleAddClick}>{user2Edit ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}