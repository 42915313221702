import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultAdAccountId } from "../constants";

const initialState = {
  adAccountId: defaultAdAccountId
};

const adAccountSlice = createSlice({
  name: "adAccount",
  initialState,
  reducers: {
    initialize: (state) => {
        const accId = JSON.parse(localStorage.getItem('adAccountId'));
        state.adAccountId = accId || initialState.adAccountId;
    },
    setAdAccountId: (state, action) => {
        const accId = action.payload;
        state.adAccountId = accId;
        localStorage.setItem('adAccountId', JSON.stringify(accId));
    }
  }
});

export default adAccountSlice;