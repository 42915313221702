import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import VideoList from '../../components/ui/VideoList';
import { useSnackbar } from "notistack";
import axios from "axios";
import { getAuth } from '@firebase/auth';
import { InputAdornment, Stack } from '@mui/material';
import { CircularProgress, Typography } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';

import constants from '../../constants';
import BundleList from '../../components/ui/BundleList';

const defaultCursor = { startAfter: '', endBefore: ''};
const emptyBundleList = {bundles: [], prevPageExists: false, nextPageExists: false};
const MAX_BUNDLES_IN_PAGE = 10;

let currBundleList = emptyBundleList;
let currForceBundleListSelectionRefresh = false;

export default function BundleSelectionDialog({ handleClose, handleAdd }) {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [bundleList, setBundleList] = React.useState(emptyBundleList);
    const [bundleListLoading, setBundleListLoading] = React.useState(false);
    const [queryCursor, setQueryCursor] = React.useState(defaultCursor);
    const [selectedBundleIds, setSelectedBundleIds] = React.useState([]);
    const [selectedBundles, setSelectedBundles] = React.useState([]);
    const [bundles2QueryVideos, setBundles2QueryVideos] = React.useState([]);
    const [queryingBundleVideos, setQueryingBundleVideos] = React.useState(false);
    const [forceBundleListSelectionRefresh, setForceBundleListSelectionRefresh] = React.useState(false);
    const adAccountId = useSelector(state => state.adAccount.adAccountId);

    React.useEffect(async () => {

        if (bundles2QueryVideos.length === 0)
          return;

        setQueryingBundleVideos(true);
        const auth = getAuth();
        try {
          const tokenRes = await auth.currentUser.getIdToken();
          const res =  await axios.post(constants.VIDEOS_ENDPOINT + '/bundle-videos/' + adAccountId, {bundles2QueryVideos}, {
            headers: {
              Authorization: 'Bearer ' + tokenRes 
            }
          });

          handleAdd(res.data);

        } catch (error) {
          console.log(error);
          enqueueSnackbar('Can not get bundle videos', { variant: 'error'});
        }
        setQueryingBundleVideos(false);        
      }, [bundles2QueryVideos]);

    React.useEffect(async () => {

        setBundleListLoading(true);
        const auth = getAuth();
        try {
          const tokenRes = await auth.currentUser.getIdToken();
          const res =  await axios.post(constants.VIDEOS_ENDPOINT + '/bundles/' + adAccountId, {limit: MAX_BUNDLES_IN_PAGE, ...queryCursor}, {
            headers: {
              Authorization: 'Bearer ' + tokenRes 
            }
          });
          setBundleList(res.data);
          currBundleList = res.data;
        } catch (error) {
          console.log(error);
          enqueueSnackbar('Can not get bundle list', { variant: 'error'});
        }
        setBundleListLoading(false);
      }, [queryCursor]);


    const handlePrevClick = () => {
        let endBefore = '';
        if (currBundleList.bundles.length)
            endBefore = currBundleList.bundles[0].created;
        const newCursor = { startAfter: '', endBefore: endBefore};
        setQueryCursor({...queryCursor, ...newCursor});
    }

    const handleNextClick = () => {
        let startAfter = '';
        if (currBundleList.bundles.length)
            startAfter = currBundleList.bundles[currBundleList.bundles.length - 1].created;
        const newCursor = { startAfter: startAfter, endBefore: ''};
        setQueryCursor({...queryCursor, ...newCursor});
    }

    const handleFirstClick = () => {
        setQueryCursor({...queryCursor, ...defaultCursor});
    }

    const handleBundleChecked = (bundle) => {
        const newIdSelection = [...selectedBundleIds, bundle.id];
        setSelectedBundleIds(newIdSelection);
        const newSelection = [...selectedBundles, bundle];
        setSelectedBundles(newSelection);
    }

    const handleBundleUnchecked = ({ id }) => {
        const newIdSelection = selectedBundleIds.filter(item => item !== id);
        setSelectedBundleIds(newIdSelection);
        const newSelection = selectedBundles.filter(item => item.id !== id);
        setSelectedBundles(newSelection);
    }

    const handleAddClick = () => {
        let bundles = [];
        for (const bundle of selectedBundles) {
            bundles.push(bundle.name);
        }
        setBundles2QueryVideos(bundles);
    };

    const handleSelectAll = () => {
      let allIds = [];
      let allBundles = [];
      for (const bundle of bundleList.bundles) {
        if (!selectedBundleIds.includes(bundle.id)) {
          allIds.push(bundle.id);
          allBundles.push(bundle);
        }
      }

      setSelectedBundleIds([...selectedBundleIds, ...allIds]);
      setSelectedBundles([...selectedBundles, ...allBundles]);
      currForceBundleListSelectionRefresh = !currForceBundleListSelectionRefresh;
      setForceBundleListSelectionRefresh(currForceBundleListSelectionRefresh);
    }

    const handleSelectNone = () => {
      setSelectedBundleIds([]);
      setSelectedBundles([]);      
      currForceBundleListSelectionRefresh = !currForceBundleListSelectionRefresh;
      setForceBundleListSelectionRefresh(currForceBundleListSelectionRefresh);
    }

    const renderSelectedCount = () => {
      if (selectedBundleIds.length) {
        if (bundleList.prevPageExists || bundleList.nextPageExists)
          return "Bundles found: >" + MAX_BUNDLES_IN_PAGE + ' | Showing: ' + bundleList.bundles.length + ' | Selected : ' + selectedBundleIds.length;
        else   
          return 'Bundles found: ' + bundleList.bundles.length + ' | Selected : ' + selectedBundleIds.length;
      }
      else
        return 'Bundles found: ' + bundleList.bundles.length;
    }

    const handleSearchTextChanged = debounce((e) => setQueryCursor({...queryCursor, searchCriteria: e.target.value.toLowerCase()}), 500);

  return (
    <div>
     
      <Dialog fullWidth maxWidth="sm" open={true} onClose={handleClose} disableEscapeKeyDown>
        <DialogTitle>{"Select Bundles"}</DialogTitle>
        <DialogContent style={{ height: "60vh" }}>
          {!queryingBundleVideos &&
          <React.Fragment>
            <TextField
                id="input-with-icon-textfield"
                fullWidth
                label="Search bundles"
                InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                    <SearchIcon />
                    </InputAdornment>
                ),
                }}
                variant="standard"
                onChange={handleSearchTextChanged}
            />
            <Typography style={{ marginTop: 20, color: 'rgba(0,0,0,0.6)' }} variant="body1">
                {renderSelectedCount()}
            </Typography>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
            >
                <Button variant="text" onClick={handleSelectAll}>
                { (bundleList.nextPageExists || bundleList.prevPageExists) ? "SELECT PAGE" : "SELECT ALL" } 
                </Button>
                <Button variant="text" onClick={handleSelectNone}>
                SELECT NONE
                </Button>
            </Stack>
            <BundleList
                key={forceBundleListSelectionRefresh}  
                height={"calc(60vh - 150px)"}       
                bundleListLoading={bundleListLoading}
                bundlesSelectable={true}
                handleBundleChecked={handleBundleChecked}
                handleBundleUnchecked={handleBundleUnchecked}
                selectedBundleIds={selectedBundleIds}
                prevExists={
                queryCursor != defaultCursor && bundleList.prevPageExists
                }
                nextExists={bundleList.nextPageExists}
                handlePrevClick={handlePrevClick}
                handleNextClick={handleNextClick}
                handleFirstClick={handleFirstClick}
                bundleList={bundleList.bundles}
            />
          </React.Fragment>
          }
          {queryingBundleVideos && <CircularProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={              
              selectedBundleIds.length == 0
            }
            onClick={handleAddClick}
          >
            {"Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}