import * as React from 'react';
import { Checkbox, Radio } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { Stack, Button } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Avatar, CircularProgress, Typography, IconButton } from '@material-ui/core';
import { Grid, Box } from '@material-ui/core';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FirstPageIcon from '@mui/icons-material/FirstPage';


const useStyles = makeStyles((theme) => ({
  sizeAvatar: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    backgroundColor: 'blue'
  },
  listItemText: {
    marginLeft: theme.spacing(1)
  }
}));


export default function BundleList({ height, bundleListLoading, radioSelection, bundlesSelectable, handleBundleSelected, bundleList, selectedBundleName, selectedBundleIds, prevExists, nextExists, handleFirstClick, handlePrevClick, handleNextClick, handleBundleChecked, handleBundleUnchecked }) {

  const classes = useStyles();

  const handleSelectionChange = (bundle) => {
    return (event) => {
      if (event.target.checked)
        handleBundleChecked(bundle);
      else
        handleBundleUnchecked(bundle);
    }
  }

  const handleSelectionChangeRadio = (bundle) => {
    return (event) => {
        if (event.target.checked)
          handleBundleSelected(bundle);
      }     
  }

  const renderLoadingScreen = () => {
    return (
        (bundleListLoading && (bundleList.length === 0)) &&
        <Grid style={{z: 10 }} container justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>    
    );
  }

  const renderSecondaryAction = (bundle) => {
      if (bundlesSelectable && !radioSelection) {
        return <Checkbox
          edge="end"
          onChange={handleSelectionChange(bundle)}
          defaultChecked={selectedBundleIds?.includes(bundle.id)}                
        />
      }

      if (bundlesSelectable && radioSelection) {
        return <Radio
          edge="end"
          onChange={handleSelectionChangeRadio(bundle)}
          checked={selectedBundleName == bundle.name}                
        />
      }

      return "";
  }

  return (
    <div>
      {(prevExists || nextExists) &&
      <Stack  direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
        <IconButton disabled={!prevExists} onClick={handleFirstClick} variant="text"><FirstPageIcon /></IconButton>
        <IconButton disabled={!prevExists} onClick={handlePrevClick} variant="text"><ArrowBackIosIcon /></IconButton>
        <IconButton disabled={!nextExists} onClick={handleNextClick} variant="text"><ArrowForwardIosIcon /></IconButton>
        <CircularProgress style={{visibility: bundleListLoading && bundleList.length > 0 ? 'visible' : 'hidden'}} />
      </Stack> 
      }      
      <List dense sx={{ maxHeight: height, width: '100%', overflow: 'auto', bgcolor: 'background.paper' }}>  
        {renderLoadingScreen()}            
        {bundleList.map((bundle) => (
          <ListItem key={bundle.id}
            secondaryAction={renderSecondaryAction(bundle)}
            disablePadding 
          >
             <ListItemButton>
              <ListItemAvatar>
                <Avatar className={classes.sizeAvatar}>{bundle.count}</Avatar>
              </ListItemAvatar>
              <ListItemText className={classes.listItemText} primary={bundle.name} secondary={bundle.userDisplayName}/>
            </ListItemButton> 
          </ListItem>))} 
        </List>            
    </div>
  );
}