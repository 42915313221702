// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/*const firebaseConfig = {
  apiKey: "AIzaSyBXqXVvXzOT5NbdK30OL0jXPji2OO_D5-E",
  authDomain: "loopdash-938a4.firebaseapp.com",
  projectId: "loopdash-938a4",
  storageBucket: "loopdash-938a4.appspot.com",
  messagingSenderId: "399556167638",
  appId: "1:399556167638:web:b98069868a28817e4c8677",
  measurementId: "G-MZLJP49Z04"
};*/

const firebaseConfig = {
  apiKey: "AIzaSyBCDeNnhwVn1aBTHRNaFQlTEwSr2cRv470",
  authDomain: "game-toolkit.firebaseapp.com",
  projectId: "game-toolkit",
  storageBucket: "game-toolkit.appspot.com",
  messagingSenderId: "1098405260016",
  appId: "1:1098405260016:web:d775e485205cf5ce647035",
  measurementId: "G-EVEKPMJ02B"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;