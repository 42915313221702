import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAuth } from "@firebase/auth";

const initialState = {
  data: [],
  isPending: false
};

const getData = createAsyncThunk(
  "retention/getData",
  async () => {
    const auth = getAuth();
    const tokenRes = await auth.currentUser.getIdToken();
    const res = await axios.get(`/api/bigquery`, {
      headers: {
        Authorization: 'Bearer ' + tokenRes 
      }
    });
    return res.data;
  }
);

const retentionSlice = createSlice({
  name: "retention",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.pending, (state, action) => {
        state.isPending = true;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isPending = false;
      })
      .addCase(getData.rejected, (state, action) => {
        state.isPending = false;
      });
  },
});

export { getData };
export default retentionSlice;
