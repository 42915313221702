import { Grid, Paper, Typography } from "@material-ui/core";


const DashboardItem = (props) => {
    return (
        <Grid {...props} item>
            <Paper style={{ padding: 20, height: '100%' }} elevation={4}>
                <Typography style={{ color: "#4287f5"}} variant="h6">{props.title}</Typography>
                {props.children}
            </Paper>    
        </Grid>
    );
}

export default DashboardItem;

