import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { LinearProgress } from '@material-ui/core';
import { Tooltip } from '@mui/material';

const renderJobStatus = (job) => {
  if (job.pending)
    return <LinearProgress variant="determinate" value={job.progress} />;
  else if (job.success)
    return <CheckCircleIcon color="success" />;
  else
    return (
      <Tooltip title={job.error}>
        <ErrorIcon sx={{ color: "red"}} />
      </Tooltip>
    );
}

export default function CampaignJobList({ jobs }) {
  
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Campaign Name</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">User</TableCell>
            <TableCell align="right">App</TableCell>
            <TableCell align="right">Platform</TableCell>
            <TableCell align="right">Country</TableCell>
            <TableCell align="right">Daily Budget</TableCell>
            <TableCell align="right">Videos Count</TableCell>
            <TableCell align="right">Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>          
          {jobs.map((job) => (
            <TableRow
              key={job.jobId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {job.name}
              </TableCell>
              <TableCell align="right">{renderJobStatus(job)}</TableCell>
              <TableCell align="right">{job.userDisplayName}</TableCell>
              <TableCell align="right">{job.appName}</TableCell>
              <TableCell align="right">{job.platform}</TableCell>
              <TableCell align="right">{job.country}</TableCell>
              <TableCell align="right">{'$' + job.dailyBudget}</TableCell>
              <TableCell align="right">{job.videoCount}</TableCell>
              <TableCell align="right">{new Date(job.created).toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
