import { React, useEffect, useState, useRef, Fragment } from "react";
import { Paper, Grid, Box } from "@material-ui/core";
import { Stack , Typography , Button, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import socketIOClient from "socket.io-client";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CampaignJobList from "./CampaignJobList";
import NewJobDialog from "./NewJobDialog";
import axios from "axios";
import { getAuth } from "@firebase/auth";
import { useSelector } from "react-redux";
import constants from '../../constants';
import AdAccountSelector from "../../components/ui/AdAccountSelector";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const RATE_LIMIT = 90;

let currForceRequerySockets = false;
let currForceRequeryCampaignList = false;
let currJobs = [];

const defaultCursor = { startAfter: '', endBefore: ''};
const emptyCampaignList = {campaigns: [], prevPageExists: false, nextPageExists: false};

let currCampaignList = emptyCampaignList;

const CampaignPage = () => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [jobs, setJobs] = useState([]);
    const [forceReQuerySockets, setForceReQuerySockets] = useState(false);
    const [isThrottling, setIsThrottling] = useState(false);
    const [isNewJobDialogOpen, setIsNewJobDialogOpen] = useState(false);
    const [campaignListLoading, setCampaignListLoading] = useState(false);
    const [queryCursor, setQueryCursor] = useState(defaultCursor);
    const [forceReQueryCampaignList, setForceReQueryCampaignList] = useState(false);
    const [businessUsage, setBusinessUsage] = useState(0.0);
    const adAccountId = useSelector(state => state.adAccount.adAccountId);
    const timer = useRef();
    const axiosCancelSource = useRef();

    const forceRequeryCampaignList = () => {     
      currForceRequeryCampaignList = !currForceRequeryCampaignList;
      setForceReQueryCampaignList(currForceRequeryCampaignList);      
    }

    const forceRequerySockets = () => {     
      currForceRequerySockets = !currForceRequerySockets;
      setForceReQuerySockets(currForceRequerySockets);      
    }

    const handleBusinessUsage = data => {
      if (data.adAccountId === adAccountId) {
        setBusinessUsage(data.usage);
      }
    }

    const handleCampaignJobStatus = (data) => {

      if (data.adAccountId === adAccountId) {

        const jobIdx = currJobs.findIndex(job => job.jobId == data.jobId);
        
        let newJobs = [...currJobs];

        if (jobIdx >= 0) {        
          newJobs[jobIdx] = data;      
        }
        else {        
          newJobs = [data, ...newJobs];
        }

        setJobs(newJobs);
        currJobs = newJobs; 

        if (data.error) {
          enqueueSnackbar('Campaign creation error: ' + data.error, { variant: 'error'});
        }
      }
    }

    useEffect(() => {
      setBusinessUsage(0.0);
      if (queryCursor === defaultCursor)
        forceRequeryCampaignList();
      else  
        setQueryCursor(defaultCursor); 
      forceRequerySockets();        
    }, [adAccountId]);

    useEffect(async () => {
      setCampaignListLoading(true);
      const auth = getAuth();
      try {
        const tokenRes = await auth.currentUser.getIdToken();
        const res =  await axios.post(constants.VIDEOS_ENDPOINT + '/campaigns/' + adAccountId, queryCursor, {
          headers: {
            Authorization: 'Bearer ' + tokenRes 
          }
        });
        currJobs = [...res.data.campaigns];
        setJobs(currJobs);

        currCampaignList = res.data;
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Can not get campaign list', { variant: 'error'});
      }
      setCampaignListLoading(false);
      forceRequerySockets();
    }, [queryCursor, forceReQueryCampaignList]);

    const handleThrottlingState = (data) => {
      setIsThrottling(data.throttling);
    }
    
    useEffect(() => {
      const socket = socketIOClient(constants.VIDEOS_ENDPOINT);
      socket.on("campaignStatus", data => {
        handleCampaignJobStatus(data)
      });
      socket.on("throttling", data => {
        handleThrottlingState(data)
      });      
      socket.on("businessUsage", data => {
        handleBusinessUsage(data)
      }); 
      return () => socket.disconnect();
    }, [forceReQuerySockets]);

    const addJob = async (job) => {

      try {
        axiosCancelSource.current = axios.CancelToken.source();
        const auth = getAuth();
        const tokenRes = await auth.currentUser.getIdToken();
        const res = await axios.post(constants.VIDEOS_ENDPOINT + '/campaign/' + adAccountId, job, {
            headers: {
              Authorization: 'Bearer ' + tokenRes 
            },
            cancelToken: axiosCancelSource.current.token    
        });         

      } catch (error) {
        console.log(error);
        if (error.__CANCEL__) {
          enqueueSnackbar('Job cancelled', { variant: 'info'});          
          return;
        }

        if (error.response.status === 503) 
          enqueueSnackbar('Server can handle a maximum of 10 jobs. Will try again', { variant: 'warning'});
        else  
          enqueueSnackbar('Job creation failed', { variant: 'warning'});
      }
    };
   
    const onAddJobClicked = () => {
      setIsNewJobDialogOpen(true);      
    }   
    
    const handleAddNewJob = (job) => {      
      setIsNewJobDialogOpen(false);
      addJob(job);
    }
  
    const handleCloseNewJob = (e,r) => {
      if (r === "backdropClick")
        return;

      setIsNewJobDialogOpen(false);
    }

    const renderLoadingScreen = () => {
      return (
          (campaignListLoading && (jobs.length === 0)) &&
          <Grid style={{z: 10 }} container justifyContent="center" alignItems="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>    
      );
    }

  return (
    <Fragment>
      {isNewJobDialogOpen && (
        <NewJobDialog
          handleAdd={handleAddNewJob}
          handleClose={handleCloseNewJob}
        />
      )}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        style={{ marginLeft: 20, marginRight: 20 }}
      >
        <Typography variant="h5">Facebook Campaign Creation Jobs</Typography>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <AdAccountSelector disabled={campaignListLoading} />
        </Stack>
      </Stack>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        spacing={2}
        xs={12}
        style={{ paddingTop: 20, paddingLeft: 20 }}
      >
        <Grid item xs="auto">
          <Paper style={{ padding: 20 }} elevation={4}>
            {isThrottling && (
              <Typography variant="subtitle1">
                {"Too many facebook requests. Please wait 5 minutes"}
              </Typography>
            )}
            <Stack
              sx={{ mt: 1 }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              {" "}
              {!isThrottling && (
                <Button
                  onClick={onAddJobClicked}
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                >
                  New Campaign
                </Button>
              )}
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                <Typography variant="body2">FB Quote Usage : </Typography>
                <LinearProgress
                  variant="determinate"
                  value={businessUsage}
                  sx={{
                    width: 100,
                    height: 12,
                    [`&.${linearProgressClasses.colorPrimary}`]: {
                      backgroundColor: businessUsage <= RATE_LIMIT ? "rgba(0,255,0,0.3)" : "rgba(255,0,0,0.3)",
                    },
                    [`& .${linearProgressClasses.bar}`]: {
                      backgroundColor: businessUsage <= RATE_LIMIT ? "green" : "red",
                    },
                  }}
                />
                <Typography variant="body1">{businessUsage}%</Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        <Grid item>
          {renderLoadingScreen()}
          {jobs.length > 0 && <CampaignJobList jobs={jobs} />}
          {jobs.length == 0 && !campaignListLoading && (
            <Typography variant="h7">No campaign creation jobs</Typography>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default CampaignPage;
