import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import VideoList from '../../components/ui/VideoList';
import {useSnackbar} from "notistack";
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import {FormControl, Select, InputLabel, MenuItem, Box, Stack} from '@mui/material';
import {Typography} from '@material-ui/core';
import VideoSelectionDialog from './VideoSelectionDialog';
import CountrySelect from '../../components/ui/CountrySelect';
import {useLocalStorage} from '../../features/localStorage/useLocalStorage';
import BundleSelectionDialog from './BundleSelectionDialog';
import constants from '../../constants';

const appNames = ["Match Color", "Pethereum", "Pethereum Website", "Number Match", "Number Merge", "Block Mania", "Number Ball", "Brick Block", "Apple Grapple"];

const defaultChoices = {appName: 0, platform: 0, dailyBudget: 25, country: {code: 'BR', label: 'Brazil', phone: '55'}}

export default function NewJobDialog({handleClose, handleAdd}) {

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [prevChoices, setPrevChoices] = useLocalStorage('NewJobDialog', defaultChoices);
    const nameTextFieldRef = React.useRef();
    const urlFieldRef = React.useRef();
    const appNameBoxRef = React.useRef();
    const platformBoxRef = React.useRef();
    const dailyBudgetBoxRef = React.useRef();
    const [errorTexts, setErrorTexts] = React.useState({
        name: '', url: ''
    });

    const [nameEntered, setNameEntered] = React.useState(false);

    const [selectedVideoIds, setSelectedVideoIds] = React.useState([]);
    const [selectedVideos, setSelectedVideos] = React.useState([]);
    const [selectedCountry, setSelectedCountry] = React.useState(null);
    const [videoSelectionOpened, setVideoSelectionOpened] = React.useState(false);

    const [bundleSelectionOpened, setBundleSelectionOpened] = React.useState(false);

    const [inReview, setInReview] = React.useState(false);

    const [jobToReview, setJobToReview] = React.useState({});
    const [currentApp, setCurrentApp] = React.useState(prevChoices.appName);

    const selectCountryHandler = (value) => {
        setSelectedCountry(value);
    }

    const checkName = () => {
        const name = nameTextFieldRef.current.value.trim();
        if (name.length === 0) {
            setErrorTexts({...errorTexts, name: 'Name can not be empty'});
            return false;
        } else {
            setErrorTexts({...errorTexts, name: ''});
        }

        return true;
    }

    const handleCancelClick = () => {
        if (inReview)
            setInReview(false);
        else
            handleClose();
    }

    const handleReviewClick = () => {
        if (!inReview) {
            if (errorTexts.name.length)
                return;

            if (checkName() && (selectedVideoIds.length > 0)) {
                const job = {
                    campaignName: nameTextFieldRef.current.value,
                    appName: appNames[appNameBoxRef.current.value],
                    platform: (platformBoxRef.current.value === 0 ? 'Android' : 'iOS'),
                    country: selectedCountry ? selectedCountry : prevChoices.country,
                    dailyBudget: dailyBudgetBoxRef.current.value,
                    url: urlFieldRef.current.value,
                    videos: selectedVideos
                };

                setJobToReview(job);
                setInReview(true);
                setPrevChoices({
                    appName: appNameBoxRef.current.value,
                    platform: platformBoxRef.current.value,
                    dailyBudget: dailyBudgetBoxRef.current.value,
                    country: job.country
                })
            }
        } else {

            setInReview(false);
            handleAdd({...jobToReview, country: jobToReview.country.code});
        }
    };

    const handleNameChange = () => {
        setNameEntered(true);
        checkName();
    }

    const handleUrlChange = () => {
        //TODO
    }

    const renderSelectedCount = () => {
        if (selectedVideoIds.length)
            return 'Videos added: ' + selectedVideoIds.length;
        else
            return 'No videos';
    }

    const handleAddVideos = (videos) => {
        let addIds = [];
        let addVideos = [];
        for (const video of videos) {
            if (!selectedVideoIds.includes(video.id) && video.videoId != "") {
                addIds.push(video.id);
                addVideos.push(video);
            }
        }

        setSelectedVideoIds([...selectedVideoIds, ...addIds]);
        setSelectedVideos([...selectedVideos, ...addVideos]);

        setVideoSelectionOpened(false);
        setBundleSelectionOpened(false);
    }

    const handleVideoSelectionClose = (e, r) => {
        if (r === "backdropClick")
            return;

        setVideoSelectionOpened(false);
    }

    const handleClearSelectedVideos = () => {
        setSelectedVideoIds([]);
        setSelectedVideos([]);
    }

    const handleRemoveVideo = (video) => {
        let newVideoIds = [...selectedVideoIds];
        let newVideos = [...selectedVideos];
        newVideoIds = newVideoIds.filter(id => id != video.id);
        newVideos = newVideos.filter(v => v.id != video.id);
        setSelectedVideoIds(newVideoIds);
        setSelectedVideos(newVideos);
    }

    const handleBundleSelectionClose = (e, r) => {
        if (r === "backdropClick")
            return;

        setBundleSelectionOpened(false);
    }

    return (
        <div>
            {videoSelectionOpened && (
                <VideoSelectionDialog
                    handleAdd={handleAddVideos}
                    handleClose={handleVideoSelectionClose}
                />
            )}
            {bundleSelectionOpened && (
                <BundleSelectionDialog
                    handleAdd={handleAddVideos}
                    handleClose={handleBundleSelectionClose}
                />
            )}
            <Dialog fullWidth maxWidth="sm" open={true} onClose={handleClose}>
                <DialogTitle>{"New Campaign"}</DialogTitle>
                <DialogContent style={{height: "80vh"}}>
                    <DialogContentText>{inReview ? 'Review campaign' : 'Enter campaign settings'}</DialogContentText>

                    <div style={{display: inReview ? 'none' : 'block'}}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            inputRef={nameTextFieldRef}
                            error={errorTexts.name.length === 0 ? false : true}
                            helperText={errorTexts.name}
                            onChange={handleNameChange}
                        />
                        <Box sx={{mt: 2}}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="app-label">App</InputLabel>
                                <Select
                                    labelId="app-label"
                                    id="app-select"
                                    label="App"
                                    inputRef={appNameBoxRef}
                                    defaultValue={prevChoices.appName}
                                    onChange={(e) => setCurrentApp(e.target.value)}
                                >
                                    <MenuItem value={0}>Match Color</MenuItem>
                                    <MenuItem value={1}>Pethereum</MenuItem>
                                    <MenuItem value={2}>Pethereum Website</MenuItem>
                                    <MenuItem value={3}>Number Match</MenuItem>
                                    <MenuItem value={4}>Number Merge</MenuItem>
                                    <MenuItem value={5}>Block Mania</MenuItem>
                                    <MenuItem value={6}>Number Ball</MenuItem>
                                    <MenuItem value={7}>Brick Block</MenuItem>
                                    <MenuItem value={8}>Apple Grapple</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box style={{display: currentApp == 2 ? 'none' : 'block'}} sx={{mt: 2}}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="pf-label">Platform</InputLabel>
                                <Select
                                    labelId="pf-label"
                                    id="pf-select"
                                    label="Platform"
                                    inputRef={platformBoxRef}
                                    defaultValue={prevChoices.platform}
                                >
                                    <MenuItem value={0}>Android</MenuItem>
                                    <MenuItem value={1}>iOS</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{mt: 2}}>
                            <CountrySelect
                                defaultValue={prevChoices.country}
                                onChange={(e, value) => {
                                    selectCountryHandler(value)
                                }}
                            />
                        </Box>
                        <Box style={{display: currentApp != 2 ? 'none' : 'block'}} sx={{mt: 2}}>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="URL"
                                type="text"
                                fullWidth
                                variant="standard"
                                inputRef={urlFieldRef}
                                error={errorTexts.url.length === 0 ? false : true}
                                helperText={errorTexts.url}
                                onChange={handleUrlChange}
                            />
                        </Box>
                        <Box sx={{mt: 2}}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="db-label">Daily Budget</InputLabel>
                                <Select
                                    labelId="db-label"
                                    id="db-select"
                                    label="Daily Budget"
                                    inputRef={dailyBudgetBoxRef}
                                    defaultValue={prevChoices.dailyBudget ? prevChoices.dailyBudget : 25}
                                >
                                    <MenuItem value={25}>$25</MenuItem>
                                    <MenuItem value={50}>$50</MenuItem>
                                    <MenuItem value={100}>$100</MenuItem>
                                    <MenuItem value={200}>$200</MenuItem>
                                    <MenuItem value={300}>$300</MenuItem>
                                    <MenuItem value={400}>$400</MenuItem>
                                    <MenuItem value={500}>$500</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={1}
                            style={{marginTop: 20}}
                        >
                            <Typography
                                style={{color: selectedVideoIds.length > constants.MAX_VIDEOS_IN_BUNDLE ? 'red' : 'black'}}
                                variant="body1">
                                {renderSelectedCount()}
                            </Typography>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={1}
                            >
                                <Button
                                    variant="text"
                                    startIcon={<AddIcon/>}
                                    onClick={() => setVideoSelectionOpened(true)}
                                >
                                    Videos
                                </Button>
                                <Button
                                    variant="text"
                                    startIcon={<AddIcon/>}
                                    onClick={() => setBundleSelectionOpened(true)}
                                >
                                    Bundles
                                </Button>
                                <Button
                                    variant="text"
                                    startIcon={<ClearIcon/>}
                                    onClick={handleClearSelectedVideos}
                                >
                                    Clear
                                </Button>
                            </Stack>
                        </Stack>
                        <VideoList
                            height={"calc(80vh - 450px)"}
                            videoListLoading={false}
                            videosSelectable={false}
                            prevExists={false}
                            nextExists={false}
                            videoList={selectedVideos}
                            videosDeleteable={true}
                            handleRemoveVideo={handleRemoveVideo}
                        />
                    </div>

                    <div style={{display: inReview ? 'block' : 'none'}}>
                        <Typography variant="body1">
                            You're about to create a campaign with name: <br/>
                            <b>{` ${jobToReview.campaignName}`}</b><br/>
                            For app: <br/>
                            <b>{` ${jobToReview.appName}`}</b><br/>
                            For platform: <br/>
                            <b>{` ${jobToReview.platform}`}</b><br/>
                            In country: <br/>
                            <b>{` ${jobToReview.country?.label}`}</b><br/>
                            Having a daily budget of : <br/>
                            <b>{`$${jobToReview.dailyBudget}`}</b><br/>
                            With these {`${selectedVideoIds.length}`} videos: <br/>
                        </Typography>
                        <VideoList
                            height={"calc(80vh - 360px)"}
                            videoListLoading={false}
                            videosSelectable={false}
                            prevExists={false}
                            nextExists={false}
                            videoList={selectedVideos}
                            videosDeleteable={false}
                        />
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelClick}>Cancel</Button>
                    <Button
                        disabled={
                            errorTexts.name.length > 0 ||
                            selectedVideoIds.length == 0 ||
                            !nameEntered ||
                            selectedVideoIds.length > constants.MAX_VIDEOS_IN_BUNDLE
                        }
                        onClick={handleReviewClick}
                    >
                        {inReview ? "Publish" : "Review"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}