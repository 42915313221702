import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import adAccountSlice from "../../features/adAccountSlice";
import store from "../../store/store";
import { adAccounts } from "../../constants";

const AdAccountSelector = ({ disabled }) => {

    const adAccountId = useSelector((state) => state.adAccount.adAccountId);

    const handleChange = (event) => {
        const idx = event.target.value;
        store.dispatch(adAccountSlice.actions.setAdAccountId(adAccounts[idx].id));
    }

    const getAccountIdx = (accId) => {
        return adAccounts.findIndex(adAcc => adAcc.id === accId);
    }

    return (
        <FormControl variant="standard">
            <InputLabel id="ad-label">Ad Account</InputLabel>
            <Select 
                style={{width: 220}}
                defaultValue={getAccountIdx(adAccountId)} 
                onChange={handleChange}
                labelId="ad-label"
                id="ad-select"
                label="Ad Account"
                disabled={disabled}
            >
                {
                    adAccounts.map((adAccount) => 
                        <MenuItem value={adAccount.idx}>{adAccount.name}</MenuItem>
                    )
                }
            </Select>
        </FormControl>
    );
};

export default AdAccountSelector;