import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../features/auth/authSlice";
import retentionSlice from "../features/retentionSlice";
import usersSlice from '../features/usersSlice';
import adAccountSlice from "../features/adAccountSlice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    retention: retentionSlice.reducer,
    users: usersSlice.reducer,
    adAccount: adAccountSlice.reducer
  },
});

export default store;
