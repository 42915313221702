import { React, useEffect, useState, useRef, Fragment } from "react";
import { Paper, Grid, Box, List, ListItem, ListItemText } from "@material-ui/core";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DropzoneDialog } from 'material-ui-dropzone';
import { Stack , Typography , Button } from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import socketIOClient from "socket.io-client";
import VideoList from '../../components/ui/VideoList';
import { getAuth } from "@firebase/auth";
import ConfirmationDialog from "../../components/ui/ConfirmationDialog";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import constants from '../../constants'
import AdAccountSelector from "../../components/ui/AdAccountSelector";
import { useSelector } from "react-redux";
import { Chip } from "@material-ui/core";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const RATE_LIMIT = 90;

const useStyles = makeStyles(theme => createStyles({
  previewChip: {
    minWidth: 160,
    maxWidth: 250
  },
  uploading2FbGrid: {
    margin: '1em'
  }
}));

const MAX_UPLOADS_AT_ONCE = 1;

const defaultCursor = { startAfter: '', endBefore: ''};
const emptyVideoList = {videos: [], prevPageExists: false, nextPageExists: false};

let currVideoList = emptyVideoList;
let currForceRequerySockets = false;
let currForceRequeryVideoList = false;
let currLoadedFiles = [];
let currIdsBeingUploaded = [];
let currThrottling = false;
let currForceClearLoadedFiles = false;

const UploadVideoPage = () => {

    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [loadedFiles, setLoadedFiles] = useState([]);
    const [totalLoadedFiles, setTotalLoadedFiles] = useState(0);
    const [uploading2Server, setUploading2Server] = useState(false);
    const [uploading2ServerProgress, setUploading2ServerProgress] = useState(0);
    const [videoList, setVideoList] = useState(emptyVideoList);
    const [videoListLoading, setVideoListLoading] = useState(false);
    const [idsBeingUploaded, setIdsBeingUploaded] = useState([]);
    const [idsBeingProcessed, setIdsBeingProcessed] = useState([]);
    const [queryCursor, setQueryCursor] = useState(defaultCursor);
    const [forceReQuerySockets, setForceReQuerySockets] = useState(false);
    const [forceReQueryVideoList, setForceReQueryVideoList] = useState(false);
    const [forceClearLoadedFiles, setForceClearLoadedFiles] = useState(false);
    const [selectedVideoIds, setSelectedVideoIds] = useState([]);
    const [isThrottling, setIsThrottling] = useState(false);
    const [cancelConfirmDialogOpened, setCancelConfirmDialogOpened] = useState(false);
    const [isDropzoneOpened, setIsDropzoneOpened] = useState(false);
    const [currentlyUploadedFileNames, setCurrentlyUploadedFileNames] = useState('');
    const [bundleName, setBundleName] = useState('');
    const [adAccountUsage, setAdAccountUsage] = useState(0.0);
    const adAccountId = useSelector(state => state.adAccount.adAccountId);
    const { displayName } = useSelector(state => state.auth.user);
    const timer = useRef();
    const axiosCancelSource = useRef();


    const forceRequeryVideoList = () => {     
      currForceRequeryVideoList = !currForceRequeryVideoList;
      setForceReQueryVideoList(currForceRequeryVideoList);      
    }

    const forceRequerySockets = () => {     
      currForceRequerySockets = !currForceRequerySockets;
      setForceReQuerySockets(currForceRequerySockets);      
    }

    const handleProcessingOnFbMsg = (data) => {
      const filteredData = data.filter(item => item.adAccountId === adAccountId);
      let ids = [];
      for (const item of filteredData) {
        ids.push(item.id);
      }
      setIdsBeingProcessed(ids);  
    }

    const handleNewThumbnail = (data) => {
      const videoIndex = currVideoList.videos.findIndex((video => video.id == data.id));
      if (videoIndex >= 0) {
        let newVideoList = currVideoList.videos;
        newVideoList[videoIndex].thumbnail = data.url;
        currVideoList = {...currVideoList, videos: newVideoList };
        setVideoList(currVideoList);        
      }
    }

    const handleUploading2FbMsg = data => { 
      const filteredData = data.filter(item => item.adAccountId === adAccountId);
      let ids = [];
      for (const item of filteredData) {
        ids.push(item.id);
      }
      setIdsBeingUploaded(ids);
      currIdsBeingUploaded = ids;
    }

    const handleThrottlingState = (data) => {
      setIsThrottling(data.throttling);
      currThrottling = data.throttling;
    }

    const handleUpload2FbFailed = (data) => {
      enqueueSnackbar('Can not upload video to facebook: ' + data.error, { variant: 'error'});
      forceRequeryVideoList();
    }

    const handleAdAccountUsage = data => {      
      if (data.adAccountId === adAccountId) {
        setAdAccountUsage(data.usage);
      }
    }

    useEffect(() => {      
      const socket = socketIOClient(constants.VIDEOS_ENDPOINT);
      socket.on("idsBeingUploaded", data => {
        handleUploading2FbMsg(data);
      });
      socket.on("idsBeingProcessed", data => {
        handleProcessingOnFbMsg(data);       
      });
      socket.on("thumbnail", data => {
        handleNewThumbnail(data)
      });
      socket.on("throttling", data => {
        handleThrottlingState(data)
      }); 
      socket.on("uploadFailed", data => {
        handleUpload2FbFailed(data)
      });  
      socket.on("newVideosInDb", data => {
        handleNewVideosInDb(data)
      });      
      socket.on("adAccountUsage", data => {
        handleAdAccountUsage(data)
      });

      return () => socket.disconnect();
    }, [forceReQuerySockets]);

    useEffect(() => {
      setAdAccountUsage(0.0);
      if (queryCursor === defaultCursor)
        forceRequeryVideoList();
      else  
        setQueryCursor(defaultCursor);
      forceRequerySockets();
    }, [adAccountId]);

    useEffect(async () => {
      setVideoListLoading(true);
      const auth = getAuth();
      try {
        const tokenRes = await auth.currentUser.getIdToken();
        const res =  await axios.post(constants.VIDEOS_ENDPOINT + '/videos/' + adAccountId, queryCursor, {
          headers: {
            Authorization: 'Bearer ' + tokenRes
          }
        });
        setVideoList(res.data);
        currVideoList = res.data;
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Can not get video list', { variant: 'error'});
      }
      setVideoListLoading(false);
      forceRequerySockets();
    }, [queryCursor, forceReQueryVideoList]);

    const handlePrevClick = () => {
      let endBefore = '';
      if (currVideoList.videos.length)
        endBefore = currVideoList.videos[0].created;
      const newCursor = { startAfter: '', endBefore: endBefore};
      setQueryCursor(newCursor);
    }

    const handleNextClick = () => {
      let startAfter = '';
      if (currVideoList.videos.length)
        startAfter = currVideoList.videos[currVideoList.videos.length - 1].created;
      const newCursor = { startAfter: startAfter, endBefore: ''};
      setQueryCursor(newCursor);
    }

    const handleFirstClick = () => {
      setQueryCursor(defaultCursor);
    }

    const handleVideoChecked = (videoId) => {
      const newSelection = [...selectedVideoIds, videoId];
      setSelectedVideoIds(newSelection);
    }

    const handleVideoUnchecked = (videoId) => {
      const newSelection = selectedVideoIds.filter(item => item !== videoId);
      setSelectedVideoIds(newSelection);
    }

    const handleNewVideosInDb = (newVideos) => {
      if (!currVideoList.prevPageExists) {
        const newVideosFiltered = newVideos.filter(video => video.adAccountId === adAccountId);
        let videosToAdd = currVideoList.videos;
        videosToAdd = [...newVideosFiltered, ...videosToAdd];
        let nextPageExists = currVideoList.nextPageExists;
        if (videosToAdd.length > 10) {
          videosToAdd = videosToAdd.slice(0,10);
          nextPageExists = true;
        }
        currVideoList = {...currVideoList, nextPageExists: nextPageExists, videos: videosToAdd}
        setVideoList(currVideoList);          
      }
    }

    useEffect(() => {
      return () => {
        if (axiosCancelSource.current)
          axiosCancelSource.current.cancel();

        clearTimeout(timer.current);
      }
    }, []);

    const uploadVideos = async (bundleName2Upload) => {

      if (currLoadedFiles.length == 0)
        return;

      if (currIdsBeingUploaded.length >= MAX_UPLOADS_AT_ONCE) {
        timer.current = setTimeout(() => uploadVideos(bundleName2Upload), 1000);
        return;
      }

      const videoLocations = currLoadedFiles.splice(0, MAX_UPLOADS_AT_ONCE - currIdsBeingUploaded.length);

      let currentFileNames = '';
      var form = new FormData();
      videoLocations.forEach(file => {
        form.append("video-files", file);
        currentFileNames = currentFileNames + ' , ' + file.name;
      });

      if (bundleName2Upload.length > 0)
        form.append("bundleName", bundleName2Upload);

      if (currentFileNames.length)
        currentFileNames = currentFileNames.slice(3);
          
      setCurrentlyUploadedFileNames(currentFileNames);
  
      try {
        axiosCancelSource.current = axios.CancelToken.source();
        const auth = getAuth();
        const tokenRes = await auth.currentUser.getIdToken();
        const res = await axios({
            method: 'post',
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + tokenRes
            },
            cancelToken: axiosCancelSource.current.token,
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            url: constants.VIDEOS_ENDPOINT + '/video/' + adAccountId,
            data: form,            
            onUploadProgress: ({total, loaded}) => { 
              setUploading2ServerProgress(Math.floor(loaded * 100 / total));
            }
        });

        if (currLoadedFiles.length == 0) {
          setUploading2Server(false);
          setBundleName('');
          setCurrentlyUploadedFileNames('');
          setLoadedFiles([]);
          setTotalLoadedFiles(0);
          return;
        }

        timer.current = setTimeout(() => uploadVideos(bundleName2Upload), 1000);  

      } catch (error) {
        console.log(error);
        if (error.__CANCEL__) {
          enqueueSnackbar('Upload cancelled', { variant: 'info'});
          clearFiles();
          setUploading2Server(false); 
          setBundleName('');
          setCurrentlyUploadedFileNames('');         
          setUploading2ServerProgress(0);
          return;
        }

        if (error.response.status === 503)
          enqueueSnackbar('Server can handle a maximum of 10 videos. Will try again', { variant: 'warning'});
        else  
          enqueueSnackbar('Upload failed. Will try again', { variant: 'warning'});

        currLoadedFiles.unshift(...videoLocations);

        timer.current = setTimeout(() => uploadVideos(bundleName2Upload), 5000);
      }
  };

  const nowToString = () => {
    const date = new Date();
    const dateStr =
      date.getFullYear() + "-" +   
      ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
      ("00" + date.getDate()).slice(-2) + " " +
      
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2) + ":" +
      ("00" + date.getSeconds()).slice(-2);

    return dateStr;  
  }

  
    const onUploadClicked = () => { 
      const dateString = nowToString();         
      const bundleNameGenerated = 'Bundle ' + dateString + ' by ' + displayName;
      setBundleName(bundleNameGenerated);
      setUploading2Server(true);
      uploadVideos(bundleNameGenerated);
    }

    const onCancelClicked = () => {
      setCancelConfirmDialogOpened(true);
    }

    const handleCancelUploadAccept = () => {
      setCancelConfirmDialogOpened(false);
      if (axiosCancelSource.current)
        axiosCancelSource.current.cancel();
      clearTimeout(timer.current);
      setUploading2Server(false);
      setBundleName('');
      setUploading2ServerProgress(0);
      clearFiles();     
    }

    const clearFiles = () => {
      currForceClearLoadedFiles = !currForceClearLoadedFiles;
      setForceClearLoadedFiles(currForceClearLoadedFiles);
      setLoadedFiles([]);
      currLoadedFiles = [];
      setTotalLoadedFiles(0);
    }

    const onClearClicked = () => {
      clearFiles();
    }

    const onAddFilesClicked = () => {
      setIsDropzoneOpened(true);
    }
    
    const handleDropzoneFilesChange = (loadedFilesList) => {
        let newFileList = [].concat(loadedFiles, loadedFilesList);
        setLoadedFiles(newFileList);
        currLoadedFiles = newFileList;
        setTotalLoadedFiles(newFileList.length);
        setIsDropzoneOpened(false);
    }

    const renderUploading2ServerIndicator = () => {
      return (
        <Box sx={{mt: 1}}>
          <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
            <Grid item>
              <Typography variant="p">{'Videos left: ' + (loadedFiles.length + 1) + ' of ' + totalLoadedFiles}</Typography>
            </Grid>
            <Grid item>
              <LinearProgress variant="determinate" value={(totalLoadedFiles - loadedFiles.length - 1) * 100 / totalLoadedFiles } />
            </Grid>
            <Grid item>
              {(uploading2ServerProgress < 100) && <Typography variant="p">Uploading video {currentlyUploadedFileNames} {bundleName ? ('to bundle ' + bundleName) : ''} ({uploading2ServerProgress}%)...</Typography>}
              {(uploading2ServerProgress >= 100) && <Typography variant="p">Waiting for next one...</Typography>}
            </Grid>
            <Grid item>
              <LinearProgress variant="determinate" value={uploading2ServerProgress } />            
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">* Please do not refresh or close your browser</Typography>
            </Grid>
          </Grid>
        </Box>
      );
    }

    const handleFileChipDelete = (file) => () => {
      let newLoadedFiles = [...loadedFiles];
      newLoadedFiles = newLoadedFiles.filter(f => f.name !== file.name);
      setLoadedFiles(newLoadedFiles);
      currLoadedFiles = newLoadedFiles;      
    }

    const renderFiles2BeUploaded = () => {
      return (
        <Fragment>
          <Grid
            container
            spacing={1}
            direction="row"

            style={{
              marginTop: 10,
              marginBottom: 10,
              maxHeight: 200,            
              maxWidth: "100%",
              overflow: "auto"
            }}
          >
            {loadedFiles.map((file) => (
              <Grid item key={file.name}>
                <Chip className={classes.previewChip} variant="outlined" label={file.name} onDelete={handleFileChipDelete(file)}/>
              </Grid>
            ))}
          </Grid>
        </Fragment>
      );
    }


  return (
    <Fragment>
      <ConfirmationDialog
        isOpened={cancelConfirmDialogOpened}
        handleAccept={handleCancelUploadAccept}
        handleReject={() => setCancelConfirmDialogOpened(false)}
        titleText="Confirm upload cancellation"
        questionText="Do you want to cancel upload?"
      />
      <DropzoneDialog
        open={isDropzoneOpened}
        acceptedFiles={["video/*"]}
        dropzoneText="Drop videos here or click"
        dialogTitle="Upload videos"
        onClose={() => setIsDropzoneOpened(false)}
        key={forceClearLoadedFiles}
        filesLimit={100}
        maxFileSize={500 * 1024 * 1024}
        showPreviews={true}
        showPreviewsInDropzone={false}
        useChipsForPreview
        previewGridProps={{ container: { spacing: 1, direction: "row" } }}
        previewText="Selected files"
        onSave={handleDropzoneFilesChange}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        style={{ marginLeft: 20, marginRight: 20 }}
      >
        <Typography variant="h5">Facebook Ad Account Videos</Typography>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <AdAccountSelector disabled={uploading2Server || videoListLoading} />
        </Stack>
      </Stack>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        spacing={2}
        xs={12}
        style={{ paddingTop: 20, paddingLeft: 20 }}
      >
        <Grid item xs="auto">
          <Paper style={{ padding: 20 }} elevation={4}>
            <Typography
              style={{ color: "#4287f5", marginBottom: 10 }}
              variant="h6"
            >
              Upload Queue
            </Typography>
            {!uploading2Server && loadedFiles.length > 0 && (
              <Typography variant="subtitle1">
                {"Videos Selected: " + loadedFiles.length}
              </Typography>
            )}
            {loadedFiles.length > 0 &&
              !uploading2Server &&
              renderFiles2BeUploaded()}
            {isThrottling && (
              <Typography variant="subtitle1">
                {"Too many uploads requested. Please wait 5 minutes"}
              </Typography>
            )}
            {uploading2Server && renderUploading2ServerIndicator()}
            <Stack
              sx={{ mt: 1 }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
              {" "}
              {!uploading2Server && !isThrottling && (
                <Button
                  onClick={onAddFilesClicked}
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                >
                  Add Videos
                </Button>
              )}
              {!uploading2Server && loadedFiles.length > 0 && (
                <Button
                  onClick={onClearClicked}
                  variant="contained"
                  startIcon={<HighlightOffIcon />}
                >
                  Cancel
                </Button>
              )}
              {!uploading2Server && !isThrottling && (
                <Button
                  disabled={loadedFiles.length === 0}
                  onClick={onUploadClicked}
                  variant="contained"
                  startIcon={<FileUploadIcon />}
                >
                  Upload
                </Button>
              )}
              {uploading2Server && (
                <Button
                  onClick={onCancelClicked}
                  variant="contained"
                  startIcon={<StopCircleIcon />}
                >
                  Cancel
                </Button>
              )}
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        <Grid item>
          <VideoList
            videoListLoading={videoListLoading}
            videosSelectable={false}
            handleVideoChecked={handleVideoChecked}
            handleVideoUnchecked={handleVideoUnchecked}
            selectedVideoIds={selectedVideoIds}
            prevExists={
              queryCursor != defaultCursor && videoList.prevPageExists
            }
            nextExists={videoList.nextPageExists}
            handlePrevClick={handlePrevClick}
            handleNextClick={handleNextClick}
            handleFirstClick={handleFirstClick}
            videoList={videoList.videos}
            idsBeingProcessed={idsBeingProcessed}
            idsBeingUploaded={idsBeingUploaded}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default UploadVideoPage;
