import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ResetPwDialog({ isOpened, handleClose, handlePwResetWithEmail }) {

    const emailTextFieldRef = React.useRef();

    const handleSendClick = () => {
        const email = emailTextFieldRef.current.value;
        handlePwResetWithEmail(email);
    }

  return (
    <div>
      <Dialog open={isOpened} onClose={handleClose}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter email address
          </DialogContentText>
          <TextField            
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            inputRef={emailTextFieldRef}
          />         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSendClick}>Send</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}