import { SwipeableDrawer } from "@material-ui/core";
import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import PeopleIcon from "@mui/icons-material/People";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import constants from '../../constants'
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import CampaignIcon from '@mui/icons-material/Campaign';
import VideoLibraryRoundedIcon from '@mui/icons-material/VideoLibraryRounded';

const AppDrawer = ({ isDrawerOpened, setDrawerOpened }) => {

  const isOwner = useSelector((state) => state.auth.user.role === constants.ROLE_OWNER);
  const isCreativeOrGrowth = useSelector((state) => state.auth.user.role === constants.ROLE_CREATIVE || state.auth.user.role === constants.ROLE_GROWTH);

  return (
    <SwipeableDrawer
      anchor="left"
      open={isDrawerOpened}
      onClose={() => setDrawerOpened(false)}
    >
      <Box
        sx={{ width: 280 }}
        role="presentation"
        onClick={() => setDrawerOpened(false)}
      >
        <List>
          <ListItem button component={Link} to="/dashboard" key="Dashboard">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          {(isOwner || isCreativeOrGrowth) &&
          <><ListItem button component={Link} to="/video-grinder" key="VideoEditor">
              <ListItemIcon>
                <MovieCreationIcon />
              </ListItemIcon>
              <ListItemText primary="Video Grinder" />
            </ListItem><ListItem button component={Link} to="/video-uploader" key="VideoUploader">
                <ListItemIcon>
                  <VideoLibraryRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="FB Ad Account Videos" />
              </ListItem>
              <ListItem button component={Link} to="/campaign-creator" key="CampaignCreator">
                <ListItemIcon>
                  <CampaignIcon />
                </ListItemIcon>
                <ListItemText primary="FB Campaign Creator" />
              </ListItem></>}
          {isOwner &&
          <ListItem button component={Link} to="/users" key="Users">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
          }
        </List>
      </Box>
    </SwipeableDrawer>
  );
};

export default AppDrawer;
