const constants = {
    ROLE_UNASSIGNED: 0,
    ROLE_OWNER: 1,
    ROLE_ADMIN: 2,
    ROLE_GROWTH: 3,
    ROLE_CREATIVE: 4,
    ROLE_PRODUCT: 5,

    MAX_VIDEOS_IN_BUNDLE: 50,

    //VIDEOS_ENDPOINT : "https://loopdash-938a4.ew.r.appspot.com",
    //VIDEOS_ENDPOINT : "https://34.65.220.39",
    VIDEOS_ENDPOINT: "https://fbadsupload.gametoolkit.io",
    // VIDEOS_ENDPOINT : "http://localhost:8080",
};

export const adAccounts = [
    {idx: 0, id: '658676978540322', name: 'Pethereum'},
    {idx: 1, id: '404719321658127', name: 'Pethereum - Test'},
    {idx: 2, id: '1994577227361339', name: 'Match Color'},
    {idx: 3, id: '4132783540124114', name: 'Match Color - Test'},
    {idx: 4, id: '223084683591470', name: 'Number Match'},
    {idx: 5, id: '1158699104776046', name: 'Number Match - Test'},
    {idx: 6, id: '240864318440393', name: 'Number Merge'},
    {idx: 7, id: '1074630657225902', name: 'Number Merge - Test'},
    {idx: 8, id: '583447039921345', name: 'Block Mania'},
    {idx: 9, id: '1605838343223492', name: 'Block Mania - Test'},
    {idx: 10, id: '3612603639028407', name: 'Number Ball'},
    {idx: 11, id: '3612603639028407', name: 'Number Ball - Test'},
    {idx: 12, id: '724450506311795', name: 'Brick Block'},
    {idx: 13, id: '923629085911642', name: 'Brick Block - Test'},
    {idx: 14, id: '437847392528217', name: 'Apple Grapple'},
    {idx: 15, id: '428424366650322', name: 'Apple Grapple - Test'}
];

export const defaultAdAccountId = '583447039921345';

/*
export const adAccounts = [
    { idx: 0, id: '588044808810201', name: 'DGlabs'},
    { idx: 1, id: '1300206847100127', name: 'DodoLabs'},
]

export const defaultAdAccountId = '588044808810201';*/


export default constants;