import { useState, Fragment, useRef } from 'react'
import TextField from '@mui/material/TextField';
import { Box, LinearProgress } from '@material-ui/core';
import Uppy from '@uppy/core'
import { DragDrop, StatusBar } from '@uppy/react'
import thumbnailGenerator from '@uppy/thumbnail-generator'
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg'
import ReactPlayer from 'react-player'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { Stack , Typography , Button } from "@mui/material";
import DashboardItem from '../components/ui/DashboardItem';
import { Grid } from '@material-ui/core';

const VideoEditor = () => {

  const [previewArr, setPreviewArr] = useState([])
  const [videoUrl, setVideoUrl] = useState(null)
  const [videoFile, setVideoFile] = useState(null)
  const [videoDuration, setVideoDuration] = useState(0)
  const [outputVideoLength, setOutputVideoLength] = useState(25)
  const [outputVideoAmount, setOutputVideoAmount] = useState(50)
  const [processing, setProcessing] = useState(false)
  const [progress, setProgress] = useState(1)
  const ffmpeg = createFFmpeg({ log: true })

  const outputVideoLengthRef = useRef();
  const outputVideoAmountRef = useRef();

  const uppy = new Uppy({
    meta: { type: 'avatar' },
    autoProceed: true,
    restrictions: { maxNumberOfFiles: 1, allowedFileTypes: ['.mp4'] }
  })

  uppy.use(thumbnailGenerator)

  uppy.on('thumbnail:generated', (file, preview) => {
    const arr = previewArr
    arr.push(preview)
    setPreviewArr([...arr])
  })

  uppy.on('file-added', (file) => {
    console.log('Added file', file)
    setVideoUrl(URL.createObjectURL(file.data))
    setVideoFile(file.data)
  })

  const handleDuration = (duration) => {
    console.log('onDuration', duration)
    setVideoDuration(duration)
  }

  const onClearClicked = () => {
    uppy.reset();
    setVideoFile(null);
    setVideoUrl(null);
    setPreviewArr([]);
    setVideoDuration(0);
  }

  const renderPreview = () => {
    if (videoUrl !== null) {
      return (
        <DashboardItem style={{minWidth: "500px"}} title='Preview'>
          <ReactPlayer
            url={videoUrl}
            className='react-player-video'
            width='100%'
            controls={true}
            onDuration={handleDuration} 
          />
          <Stack sx={{ mt: 1 }} direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Button disabled={processing} onClick={onClearClicked} variant="contained">Clear</Button>
          </Stack>
        </DashboardItem>
      )
    } else {
      
      return (
        <DashboardItem style={{minWidth: "500px"}} title='Upload'>
          <DragDrop uppy={uppy} />
        </DashboardItem>
      )
    }
  }

  function getUniqueNumberArray(length, max) {
    const arr = []
    while (arr.length < length) {
      const r = Math.floor(Math.random() * max)
      if (arr.indexOf(r) === -1) arr.push(r)
    }
    return arr
  }

  function isVideoLengthValid() {
    const intVideoLength = parseInt(outputVideoLength)
    return Number.isInteger(intVideoLength) && intVideoLength < videoDuration
  }

  function isVideoAmountValid() {
    const intVideoAmount = parseInt(outputVideoAmount)
    return Number.isInteger(intVideoAmount) && (intVideoAmount < videoDuration)
  }

  async function trimVideo() {
    await ffmpeg.load()
    ffmpeg.FS('writeFile', videoFile.name, await fetchFile(videoFile))
    const zip = new JSZip()
    const uniqueArr = getUniqueNumberArray(outputVideoAmount, videoDuration - outputVideoLength + 1)

    for (let i = 0; i < uniqueArr.length; i++) {
      const outputName = `${videoFile.name}_part${i + 1}.mp4`
      const from = uniqueArr[i].toString()
      const to = (uniqueArr[i] + parseInt(outputVideoLength)).toString()
      await ffmpeg.run('-ss', from, '-i', videoFile.name, '-t', outputVideoLength.toString(), '-c', 'copy', '-avoid_negative_ts', 'make_zero', outputName)
      const data = ffmpeg.FS('readFile', outputName)
      const blob = new Blob([data.buffer], { type: 'video/mp4' })
      zip.file(outputName, blob)
      setProgress(parseInt(100 * (i + 1) / uniqueArr.length) - 1)
    }
    const content = await zip.generateAsync({ type: "blob" })
    saveAs(content, `${videoFile.name}_trim.zip`)
    setProgress(100)
  }

  function isReadyToProcess() {
    return isVideoLengthValid() && isVideoAmountValid() && videoUrl && !processing
  }

  const onProcessClicked = async () => {
    setProcessing(true)
    await trimVideo()
    setProcessing(false)
    setProgress(0)
  }

  function renderProgressBar() {
    if (!processing)
      return null;
    let text = <span> Processing... {progress}% </span>
    if (progress >= 100) text = <span> Completed! </span>
    if (processing) {
      return (
        <div>
          {text}
          <LinearProgress variant="determinate" value={progress} />          
        </div>
      )
    } else {
      return null
    }
  }

  function renderButtons() {
    let videoDurationValidation = true;
    let videoAmountValidation = true;

    if (videoUrl)
    {
      if (isVideoLengthValid()) videoDurationValidation = true;
      else videoDurationValidation = false;
      if (isVideoAmountValid()) videoAmountValidation = true;
      else videoAmountValidation = false;
    }

    return (
      <DashboardItem title="Grind Video">
        <TextField
            autoFocus
            margin="dense"
            label="Video Duration"
            type="text"
            fullWidth
            variant="standard"
            inputRef={outputVideoLengthRef}
            defaultValue={outputVideoLength}
            error={!videoDurationValidation}
            onChange={e => {
              setOutputVideoLength(e.target.value)
            }}
          /> 
          <TextField
            autoFocus
            margin="dense"
            label="Video Amount"
            type="text"
            fullWidth
            variant="standard"
            inputRef={outputVideoAmountRef}
            defaultValue={outputVideoAmount}
            error={!videoAmountValidation}
            onChange={e => {
              setOutputVideoAmount(e.target.value)
            }}
          /> 
          <Stack sx={{ mt: 1 }} direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Button disabled={!isReadyToProcess()} onClick={onProcessClicked} variant="contained">Grind</Button>
          </Stack>
          {renderProgressBar()}
      </DashboardItem>
    )
  }

  return (
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
        xs={12}
        style={{ paddingTop: 20, paddingLeft: 20}}
        >   
        {renderPreview()}
        {renderButtons()}
      </Grid>
  )
}

export default VideoEditor
