import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import constants from '../../constants';

import { getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence, signOut } from "firebase/auth";

const initialState = {
  isLoggedIn: false,
  isLoginPending: false,
  isLoginWithTokenPending: true,
  user: {
    displayName: "",
    email: "",
    role: constants.ROLE_UNASSIGNED
  }
};

const login = createAsyncThunk(
  "auth/login",
  async (usernameAndPassword, thunkAPI) => {
    
    const auth = getAuth();
    await setPersistence(auth, browserLocalPersistence);

    const userCreds = await signInWithEmailAndPassword(auth, usernameAndPassword.username,
      usernameAndPassword.password);
    const tokenResult = await auth.currentUser.getIdTokenResult();   
    return { userCreds: userCreds, role: tokenResult.claims.role };  
  }
);

const loginWithToken = createAsyncThunk(
  "auth/loginWithToken",
  async () => {
    const auth = getAuth();
    const tokenRes = await auth.currentUser.getIdToken();
    const res = await axios.get(`/api/user`, {
        headers: {
          Authorization: 'Bearer ' + tokenRes 
        }
      }
    );
    return res.data;
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      const auth = getAuth();
      signOut(auth);
    },
    notoken: (state) => {
      state.isLoggedIn = false;
      state.isLoginWithTokenPending = false;
      state.isLoginPending = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.isLoginPending = true;
        state.isLoginWithTokenPending = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        const {userCreds, role } = action.payload;
        state.isLoginPending = false;
        state.isLoginWithTokenPending = false;
        state.isLoggedIn = true;  
        state.user = {...state.user, ...userCreds.user, role: role };      
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoginPending = false;
        state.isLoginWithTokenPending = false;
        state.isLoggedIn = false;
      })
      .addCase(loginWithToken.pending, (state, action) => {
        state.isLoginWithTokenPending = true;
      })
      .addCase(loginWithToken.fulfilled, (state, action) => {
        state.isLoginWithTokenPending = false;
        state.isLoggedIn = true;  
        state.user = action.payload.user;      
      })
      .addCase(loginWithToken.rejected, (state, action) => {
        state.isLoginWithTokenPending = false;
        state.isLoggedIn = false;
      });
  },
});

export { login, loginWithToken };
export default authSlice;
