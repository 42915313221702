import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import VideoList from '../../components/ui/VideoList';
import { useSnackbar } from "notistack";
import axios from "axios";
import { getAuth } from '@firebase/auth';
import { InputAdornment, Stack } from '@mui/material';
import { Typography } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';

import constants from '../../constants';

const defaultCursor = { startAfter: '', endBefore: ''};
const emptyVideoList = {videos: [], prevPageExists: false, nextPageExists: false};
const MAX_VIDEOS_IN_PAGE = 100;

let currVideoList = emptyVideoList;
let currForceVideoListSelectionRefresh = false;

export default function VideoSelectionDialog({ handleClose, handleAdd }) {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [videoList, setVideoList] = React.useState(emptyVideoList);
    const [videoListLoading, setVideoListLoading] = React.useState(false);
    const [queryCursor, setQueryCursor] = React.useState(defaultCursor);
    const [selectedVideoIds, setSelectedVideoIds] = React.useState([]);
    const [selectedVideos, setSelectedVideos] = React.useState([]);
    const [forceVideoListSelectionRefresh, setForceVideoListSelectionRefresh] = React.useState(false);
    const adAccountId = useSelector(state => state.adAccount.adAccountId);

    React.useEffect(async () => {

        if (!queryCursor.searchCriteria || queryCursor.searchCriteria == "") {
          setVideoList(emptyVideoList);
          currVideoList = emptyVideoList;
          return;
        }

        setVideoListLoading(true);
        const auth = getAuth();
        try {
          const tokenRes = await auth.currentUser.getIdToken();
          const res =  await axios.post(constants.VIDEOS_ENDPOINT + '/videos/' + adAccountId, {limit: MAX_VIDEOS_IN_PAGE, ...queryCursor}, {
            headers: {
              Authorization: 'Bearer ' + tokenRes 
            }
          });
          setVideoList(res.data);
          currVideoList = res.data;
        } catch (error) {
          console.log(error);
          enqueueSnackbar('Can not get video list', { variant: 'error'});
        }
        setVideoListLoading(false);
      }, [queryCursor]);


    const handlePrevClick = () => {
        let endBefore = '';
        if (currVideoList.videos.length)
            endBefore = currVideoList.videos[0].created;
        const newCursor = { startAfter: '', endBefore: endBefore};
        setQueryCursor({...queryCursor, ...newCursor});
    }

    const handleNextClick = () => {
        let startAfter = '';
        if (currVideoList.videos.length)
            startAfter = currVideoList.videos[currVideoList.videos.length - 1].created;
        const newCursor = { startAfter: startAfter, endBefore: ''};
        setQueryCursor({...queryCursor, ...newCursor});
    }

    const handleFirstClick = () => {
        setQueryCursor({...queryCursor, ...defaultCursor});
    }

    const handleVideoChecked = (video) => {
        const newIdSelection = [...selectedVideoIds, video.id];
        setSelectedVideoIds(newIdSelection);
        const newSelection = [...selectedVideos, video];
        setSelectedVideos(newSelection);
    }

    const handleVideoUnchecked = ({ id }) => {
        const newIdSelection = selectedVideoIds.filter(item => item !== id);
        setSelectedVideoIds(newIdSelection);
        const newSelection = selectedVideos.filter(item => item.id !== id);
        setSelectedVideos(newSelection);
    }

    const handleAddClick = () => {
        handleAdd(selectedVideos);
    };

    const handleSelectAll = () => {
      let allIds = [];
      let allVideos = [];
      for (const video of videoList.videos) {
        if (!selectedVideoIds.includes(video.id) && video.videoId != "") {
          allIds.push(video.id);
          allVideos.push(video);
        }
      }

      setSelectedVideoIds([...selectedVideoIds, ...allIds]);
      setSelectedVideos([...selectedVideos, ...allVideos]);
      currForceVideoListSelectionRefresh = !currForceVideoListSelectionRefresh;
      setForceVideoListSelectionRefresh(currForceVideoListSelectionRefresh);
    }

    const handleSelectNone = () => {
      setSelectedVideoIds([]);
      setSelectedVideos([]);      
      currForceVideoListSelectionRefresh = !currForceVideoListSelectionRefresh;
      setForceVideoListSelectionRefresh(currForceVideoListSelectionRefresh);
    }

    const renderSelectedCount = () => {
      if (selectedVideoIds.length) {
        if (videoList.prevPageExists || videoList.nextPageExists)
          return "Videos found: >" + MAX_VIDEOS_IN_PAGE + ' | Showing: ' + videoList.videos.length + ' | Selected : ' + selectedVideoIds.length;
        else   
          return 'Videos found: ' + videoList.videos.length + ' | Selected : ' + selectedVideoIds.length;
      }
      else
        return 'Videos found: ' + videoList.videos.length;
    }

    const handleSearchTextChanged = debounce((e) => setQueryCursor({...queryCursor, searchCriteria: e.target.value.toLowerCase()}), 500);

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" open={true} onClose={handleClose}>
        <DialogTitle>{"Select Videos"}</DialogTitle>
        <DialogContent style={{ height: "60vh" }}>
          <TextField
            id="input-with-icon-textfield"
            fullWidth
            label="Search videos"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            onChange={handleSearchTextChanged}
          />
          <Typography style={{ marginTop: 20, color: 'rgba(0,0,0,0.6)' }} variant="body1">
            {renderSelectedCount()}
          </Typography>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Button variant="text" onClick={handleSelectAll}>
              { (videoList.nextPageExists || videoList.prevPageExists) ? "SELECT PAGE" : "SELECT ALL" } 
            </Button>
            <Button variant="text" onClick={handleSelectNone}>
              SELECT NONE
            </Button>
          </Stack>
          <VideoList
            key={forceVideoListSelectionRefresh}  
            height={"calc(60vh - 150px)"}       
            videoListLoading={videoListLoading}
            videosSelectable={true}
            handleVideoChecked={handleVideoChecked}
            handleVideoUnchecked={handleVideoUnchecked}
            selectedVideoIds={selectedVideoIds}
            prevExists={
              queryCursor != defaultCursor && videoList.prevPageExists
            }
            nextExists={videoList.nextPageExists}
            handlePrevClick={handlePrevClick}
            handleNextClick={handleNextClick}
            handleFirstClick={handleFirstClick}
            videoList={videoList.videos}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={              
              selectedVideoIds.length == 0
            }
            onClick={handleAddClick}
          >
            {"Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}