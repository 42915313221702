import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@mui/material/Grid';

const WaitIndicator = () => {
    return (
        <Grid container justifyContent="center" sx={{mt:2, mb:2}}>
          <CircularProgress />
        </Grid>
    )
};

export default WaitIndicator;