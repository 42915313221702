import { React, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getData, deleteUsers, addUser, updateUser } from "../../features/usersSlice";
import { Button, IconButton, Box } from "@mui/material";
import { Stack , Typography } from "@mui/material";
import CreateIcon from '@mui/icons-material/Create';
import { useSnackbar } from "notistack";
import AddUserDialog from "./AddUserDialog";
import ConfirmationDialog from "../../components/ui/ConfirmationDialog";
import constants from "../../constants";
import PageWaitIndicator from '../../components/ui/PageWaitIndicator';

let user2Edit;

export default function UsersPage() {
  const { users, isPending, isUserUpdatePending } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [selectedUidsList, setSelectedUidsList] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [ addDialogOpened, setAddDialogOpened ] = useState(false);
  const [ deleteConfirmDialogOpened, setDeleteConfirmDialogOpened] = useState(false);

  const handleEditClick = (e, uid) => {
    if (isUserUpdatePending)
      return;
    e.stopPropagation();
    const user = users.find(user => user.uid === uid);
    user2Edit = user;
    setAddDialogOpened(true);
  }

  const columns = [
    { field: "displayName", headerName: "Display Name", flex: 1, disableClickEventBubbling: true },
    { field: "email", headerName: "Email", flex: 1, disableClickEventBubbling: true },
    { field: "role", headerName: "Role", flex: 1, disableClickEventBubbling: true, valueFormatter: ({ value }) => {
        if (value == constants.ROLE_ADMIN)
          return "Administrator";
        else if (value == constants.ROLE_OWNER)
          return "Owner";
        else if (value == constants.ROLE_UNASSIGNED)
          return "Not Assigned";
        else if (value == constants.ROLE_CREATIVE)
          return "Creative";
        else if (value == constants.ROLE_GROWTH)
          return "Growth";
        else if (value == constants.ROLE_PRODUCT)
          return "Product";
        else
          return "Unknown";
      } 
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Edit',
      width: 100,
      disableClickEventBubbling: true,
      renderCell: ({ row }) => {
        return (
          <IconButton color="primary" component="span" onClick={(e) => handleEditClick(e,row.uid)}>
            <CreateIcon />
          </IconButton>
        )
      },
    },
  ];

  useEffect(async () => {    
    try {
      await dispatch(getData()).unwrap();
    } catch (error) {
      enqueueSnackbar('Can not get users', { variant: 'error'});
    }
  }, []);

  const handleDeleteClick = () => {
    let finalUsers = [];
    for(let user of users){
      if (!selectedUidsList.includes(user.uid))
      finalUsers.push(user);
    }

    let ownerExists = false;
    for(let user of finalUsers){
      if (user.role === constants.ROLE_OWNER) {
        ownerExists = true;
        break;
      }
    }

    if (!ownerExists) {
      enqueueSnackbar('You are not allowed to delete the owner', { variant: 'info'});
      return;
    }

    setDeleteConfirmDialogOpened(true);
  }

  const handleDeleteAccept = async () => {
    try {
      await dispatch(deleteUsers(selectedUidsList)).unwrap();
    } catch (error) {
      enqueueSnackbar('Can not delete users', { variant: 'warning'});
    }

    setDeleteConfirmDialogOpened(false);
  }

  const handleAddClick = () => {
    user2Edit = undefined;
    setAddDialogOpened(true);
  }

  const handleAddClose = () => {
    setAddDialogOpened(false);
  }

  const handleAddUser = async (userDetails) => {
    try {
      await dispatch(addUser(userDetails)).unwrap();
    } catch (error) {
      enqueueSnackbar('Can not add user', { variant: 'warning'});
    }

    setAddDialogOpened(false);
  }

  const handleEditUser = async (userDetails) => {
    try {
      await dispatch(updateUser(userDetails)).unwrap();
    } catch (error) {
      enqueueSnackbar('Can not update user', { variant: 'warning'});
    }

    setAddDialogOpened(false);
  }

  if (isPending) {    
    return (
      <PageWaitIndicator />
    )
  }

  return (
    <div style={{ height: 400, width: "100%" }}>
      <ConfirmationDialog 
        isOpened={deleteConfirmDialogOpened} 
        handleAccept={handleDeleteAccept} 
        handleReject={() => setDeleteConfirmDialogOpened(false)}
        titleText="Confirm user deletion"
        questionText="Do you want to delete selected users?"
      />
      { addDialogOpened && 
      <AddUserDialog 
        handleAdd={handleAddUser} 
        handleEdit={handleEditUser}
        handleClose={handleAddClose}
        user2Edit={user2Edit}
      /> }
      <Typography sx={{ m: 1 }} variant="h5">Users</Typography>
      <DataGrid
        sx={ { height: 400 } }
        rows={users}
        columns={columns}
        pageSize={5}
        classes={{ height: 400 }}
        rowsPerPageOptions={[5]}
        getRowId={(row) => row.uid}
        checkboxSelection
        onSelectionModelChange={ model => {
          setSelectedUidsList(model);
          setSelectedRowCount(model.length);
        } }
      />
      <Stack sx={{ m: 1 }} direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
        <Button disabled={isUserUpdatePending} onClick={handleAddClick} variant="contained">Add User</Button>
        <Button disabled={isUserUpdatePending} onClick={handleDeleteClick} variant="contained" disabled={selectedRowCount === 0}>Delete Selected</Button>
      </Stack>
    </div>
  );
}
