import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAuth } from "@firebase/auth";

const initialState = {
  users: [],
  isPending: false,
  isUserUpdatePending: false
};

const getData = createAsyncThunk(
  "users/getData",
  async (_, { rejectWithValue }) => {
    try {
      const auth = getAuth();
      const tokenRes = await auth.currentUser.getIdToken();
      const res = await axios.get(`/api/user/search`, {
        headers: {
          Authorization: 'Bearer ' + tokenRes 
        }
      });
      return res.data; 
    } catch (error) {
      console.error('Can not get users:' + error);
      return rejectWithValue(error);
    }
  }
);

const deleteUsers = createAsyncThunk(
  "users/deleteUsers",
  async (userEmails, { rejectWithValue }) => {
    try {
      const auth = getAuth();
      const tokenRes = await auth.currentUser.getIdToken();
      await axios.post('/api/user/delete', userEmails, {
        headers: {
          Authorization: 'Bearer ' + tokenRes 
        }
      });
      const res = await axios.get(`/api/user/search`, {
        headers: {
          Authorization: 'Bearer ' + tokenRes 
        }
      });
      return res.data;       
    } catch (error) {
      console.error('Can not delete users:' + error);
      return rejectWithValue(error);
    } 
  }
);

const addUser = createAsyncThunk(
  "users/addUser",
  async (userDetails, { rejectWithValue }) => {
    try {
      const auth = getAuth();
      const tokenRes = await auth.currentUser.getIdToken();
      await axios.post('/api/auth/register', userDetails, {
        headers: {
          Authorization: 'Bearer ' + tokenRes 
        }
      });
      const res = await axios.get(`/api/user/search`, {
        headers: {
          Authorization: 'Bearer ' + tokenRes 
        }
      });
      return res.data;       
    } catch (error) {
      console.error('Can not add user:' + error);
      return rejectWithValue(error);
    } 
  }
);

const updateUser = createAsyncThunk(
  "users/updateUser",
  async (userDetails, { rejectWithValue }) => {
    try {
      const auth = getAuth();
      const tokenRes = await auth.currentUser.getIdToken();
      await axios.post('/api/auth/update', userDetails ,{
        headers: {
          Authorization: 'Bearer ' + tokenRes 
        }        
      });
      const res = await axios.get(`/api/user/search`, {
        headers: {
          Authorization: 'Bearer ' + tokenRes 
        }
      });
      return res.data;       
    } catch (error) {
      console.error('Can not update user:' + error);
      return rejectWithValue(error);
    } 
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.pending, (state, action) => {
        state.isPending = true;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.users = action.payload.users;
        state.isPending = false;
      })
      .addCase(getData.rejected, (state, action) => {
        state.isPending = false;
      })
      .addCase(deleteUsers.pending, (state, action) => {
        state.isPending = true;
      })
      .addCase(deleteUsers.fulfilled, (state, action) => {
        state.users = action.payload.users;
        state.isPending = false;
      })
      .addCase(deleteUsers.rejected, (state, action) => {
        state.isPending = false;
      })
      .addCase(addUser.pending, (state, action) => {
        state.isPending = true;
        state.isUserUpdatePending = true;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.users = action.payload.users;
        state.isPending = false;
        state.isUserUpdatePending = false;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.isPending = false;
        state.isUserUpdatePending = false;
      })
      .addCase(updateUser.pending, (state, action) => {
        state.isPending = true;
        state.isUserUpdatePending = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.users = action.payload.users;
        state.isPending = false;
        state.isUserUpdatePending = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isUserUpdatePending = false;
        state.isPending = false;
      })
      ;
  },
});

export { getData, deleteUsers, addUser, updateUser };
export default usersSlice;
