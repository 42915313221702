
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ChangePasswordDialog({ handleClose, handleChangePassword }) {

    const oldPasswordRef = React.useRef();
    const passwordTextFieldRef = React.useRef();
    const repasswordTextFieldRef = React.useRef();
    const [ errorTexts, setErrorTexts ] = React.useState({
      password: '',
      repassword: ''
    });

    const checkPassword = () => {
      const password = passwordTextFieldRef.current.value;
      if (password.length === 0) {
        setErrorTexts({ ...errorTexts, password: 'Password can not be empty'});
        return false;
      }
      else {
        if (password.length < 6) {
          setErrorTexts({ ...errorTexts, password: 'Password must be at least 6 characters long'});
          return false;
        }
        else {
          setErrorTexts({ ...errorTexts, password: ''});
        }        
      }

      return true;
    }

    const checkRepassword = () => {
      const password = passwordTextFieldRef.current.value;
      const repassword = repasswordTextFieldRef.current.value;
      if (password.length && (repassword != password)) {
        setErrorTexts({ ...errorTexts, repassword: 'Passwords dont match'});
        return false;
      }
      else {
        setErrorTexts({ ...errorTexts, repassword: ''});
      }

      return true;
    }

    const handleChangeClick = () => {
      if (errorTexts.password.length + errorTexts.repassword.length) 
        return;

      if (checkPassword() && checkRepassword()) {
        const oldPw = oldPasswordRef.current.value;
        const pw = passwordTextFieldRef.current.value;

        handleChangePassword(oldPw, pw);
      }
    };

    const handlePasswordChange = () => {
      checkPassword();
    }

    const handleRepasswordChange = () => {
      checkRepassword();
    }

  return (
    <div>
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <TextField            
            margin="dense"
            label="Current Password"
            type="password"
            fullWidth
            variant="standard"
            inputRef={oldPasswordRef}            
          /> 
          <TextField            
            margin="dense"
            label="New Password"
            type="password"
            fullWidth
            variant="standard"
            inputRef={passwordTextFieldRef} 
            onChange={handlePasswordChange}  
            error={errorTexts.password.length === 0 ? false : true }
            helperText={errorTexts.password}         
          /> 
          <TextField            
            margin="dense"
            label="Re-enter New Password"
            type="password"
            fullWidth
            variant="standard"
            inputRef={repasswordTextFieldRef}  
            onChange={handleRepasswordChange}   
            error={errorTexts.repassword.length === 0 ? false : true }
            helperText={errorTexts.repassword}         
          />    
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={errorTexts.password.length + errorTexts.repassword.length > 0}onClick={handleChangeClick}>Change</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}