import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function ConfirmationDialog({titleText, questionText, isOpened, handleAccept, handleReject}) {
  const theme = useTheme();
  const fullScreen = false;

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={isOpened}
        onClose={handleReject}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {titleText}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {questionText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleReject}>
            No
          </Button>
          <Button onClick={handleAccept} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
