import React from "react";
import TextField from "@material-ui/core/TextField";
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box } from "@mui/system";

export default function BasicDateRangePicker({startDate, endDate, handleChange}) {
  const [selectedDateRange, setSelectedDateRange] = React.useState([startDate, endDate]);

  const handleDateRangeSelected = (date) => {
    setSelectedDateRange(date);
    handleChange(date);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        startText="Start Date"
        endText="End Date"
        value={selectedDateRange}
        onChange={date => handleDateRangeSelected(date)}
        renderInput={(startProps, endProps) => (
          <>
            <TextField {...startProps} />
            <Box sx={{ mx: 2 }}> to </Box>
            <TextField {...endProps} />
          </>
        )}
      />
    </LocalizationProvider>
  );
}