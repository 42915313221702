import Header from "./components/ui/Header";
import LoginPage from "./pages/LoginPage";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import DashboardPage from "./pages/DashboardPage";
import { useSelector } from "react-redux";
import store from "./store/store";
import authSlice, { loginWithToken } from "./features/auth/authSlice";
import UsersPage from "./pages/UsersPage";
import Error404 from "./pages/Error404";
import { useState } from "react";
import AppDrawer from "./components/ui/AppDrawer";
import constants from './constants'
import VideoEditor from "./pages/VideoEditor";
import UploadVideoPage from "./pages/UploadVideoPage";
/* do not delete */
import firebaseApp from "./features/auth/firebaseapp";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { CircularProgress, ThemeProvider, createTheme, makeStyles, createStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import CampaignPage from "./pages/CampaignPage";
import adAccountSlice from "./features/adAccountSlice";

const theme = createTheme({
  palette: {
    primary: {
      main: '#232323'
    }
  },
});

const useStyles = makeStyles(theme => createStyles({
  centeredProgress: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    margin: "auto"
  }
}));

const auth = getAuth();
onAuthStateChanged(auth, async (user) => {
  if (user) {
    const tokenRes = await user.getIdToken();
    if (tokenRes) {
      store.dispatch(loginWithToken());
    }
  } else {
    store.dispatch(authSlice.actions.notoken());
  }
});

store.dispatch(adAccountSlice.actions.initialize());

function App() {
  const classes = useStyles();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isOwner = useSelector((state) => state.auth.user.role === constants.ROLE_OWNER);
  const isCreativeOrGrowth = useSelector((state) => state.auth.user.role === constants.ROLE_CREATIVE || state.auth.user.role === constants.ROLE_GROWTH);
  const { isLoginPending, isLoginWithTokenPending } = useSelector((state) => state.auth);
  const [isDrawerOpened, setDrawerOpened] = useState(false);

  if (!isLoggedIn && !isLoginWithTokenPending) {
    return <LoginPage />;
  }

  if (isLoginWithTokenPending) {
    return (
      <CircularProgress className={classes.centeredProgress}/>
    );
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Header setDrawerOpened={setDrawerOpened} />
        <BrowserRouter>
          <AppDrawer
            isDrawerOpened={isDrawerOpened}
            setDrawerOpened={setDrawerOpened}
          />
          <Switch>
            <Route path="/dashboard" component={DashboardPage} />
            <Route exact path="/">
              {isOwner && <Redirect to="/dashboard" />}
              {isCreativeOrGrowth && <Redirect to="/campaign-creator" />}
            </Route>
            {(isOwner || isCreativeOrGrowth) && <Route path="/video-grinder" component={VideoEditor} />}
            {(isOwner || isCreativeOrGrowth) && <Route path="/video-uploader" component={UploadVideoPage} />}
            {(isOwner || isCreativeOrGrowth) && <Route path="/campaign-creator" component={CampaignPage} />}
            {isOwner && <Route path="/users" component={UsersPage} />}
            {!isLoginPending && !isLoginWithTokenPending && <Route component={Error404} />}
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
