import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => createStyles({
    centeredProgress: {      
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      top: theme.mixins.toolbar.minHeight,
      margin: "auto"
    }
  }));

const PageWaitIndicator = () => {

    const classes = useStyles();

    return (
        <CircularProgress className={classes.centeredProgress} />
    )
};

export default PageWaitIndicator;