import { React, useEffect, useState } from "react";
import { Box } from "@mui/system";
//import axios from "axios";
//import { getAuth } from "@firebase/auth";
import RetentionCard from "./RetentionCard"

function Iframe(props) {
  return (
    <div>          
      <iframe src={props.src} height={props.height} width={props.width} sandbox="allow-same-origin allow-scripts allow-popups allow-forms"/>
  </div>
  );
}

const Dashboard = () => {

  /*const [ssoUrl, setSsoUrl] = useState("");

  useEffect(async () => {

    const auth = getAuth();
    try {
      const tokenRes = await auth.currentUser.getIdToken();
      const res = await axios.post("/api/user/lsso", { embedUrl: "/embed/dashboards-next/4"}, {
        headers: {
          Authorization: "Bearer " + tokenRes,
        },
      });

      setSsoUrl(res.data);
    } catch (error) {
      console.log(error);
      //enqueueSnackbar("Can not get dashboard", { variant: "error" });
    }
  }, []);*/

  return (
    <Box sx={{ m: 2 }}>
      <RetentionCard />
    </Box>
  );
};

export default Dashboard;
